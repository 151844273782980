// core
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// components
import ConfigsAuthentication from '../configsAuthentication';
import Resolutions from '../resolutions';
import TableConfigsDesktop from '../tableConfigsDesktop';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { resolutionsActions } from '../../store/domains/resolutions/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import {
  validateURL,
  sendScreensToServer,
  checkIfCurrentConfigsCanBeChecked,
  createScreensConfig,
} from '../../helpers';
import useTranslations from '../../hooks/useTranslations';
import { api } from '../../api';
//default settings
import { app_metadata_namespace } from '../../settings.json';
//socket
import { _socket as socket } from '../../socket';
// styles
import './configDesktopWithURL.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function ConfigDesktopWithURL() {
  // hooks
  const dispatch = useDispatch();
  const isURLValid = useSelector((state: RootState) => state.ui.isURLValid);
  const url = useSelector((state: RootState) => state.ui.url);
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesWithURL);
  const selectionStatus = useSelector((state: RootState) => state.ui.massSelectionStatus);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const credentialsForBasicAuth = useSelector(
    (state: RootState) => state.ui.credentialsForBasicAuth
  );
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const [isPanelActive, setIsPanelActive] = useState(false);
  const [showConfigNotCheckedError, setShowConfigNotCheckedError] = useState(false);
  const [configDesktopWithUrlStyle, setConfigDesktopWithUrlStyle] = useState({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCreateScreensBtnDisabled, setIsCreateScreensBtnDisabled] = useState(false);
  const [areCredentialsConfirmed, setAreCredentialsConfirmed] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const { user } = useAuth0();
  const {
    pleaseEnterYourURL,
    pleaseEnterAValidURL,
    pleaseCheckAnyConfiguration,
    testingURL,
    createScreens,
    changeTestedURL,
  } = useTranslations();

  // actions destructuring
  const {
    checkAllConfigs,
    checkBrowserGroupConfigs,
    checkOSGroupConfigs,
  } = configsCheckboxesWithURLActions;

  // compute CSS classes
  const urlPanelClasses = cx('config-desktop-with-URL__panel', {
    active: isPanelActive,
  });
  const inputClasses = cx('config-desktop-with-URL__input', {
    invalid: !isURLValid,
  });
  const validationClasses = cx('validation-message', { active: !isURLValid });
  const isCheckedClasses = cx('validation-message', { active: showConfigNotCheckedError });

  useEffect(() => {
    function hidePanel(event) {
      if (
        isPanelActive &&
        !formRef.current.contains(event.target) &&
        event.target.className.indexOf('demoreminder') === -1 &&
        event.target.className.indexOf('configs-authentication') === -1 &&
        event.target.className.indexOf('login-profile-creator-popup') === -1 &&
        event.target.className.indexOf('select') === -1 &&
        event.target.className.indexOf('dialog') === -1
      ) {
        setIsPanelActive(false);
        setConfigDesktopWithUrlStyle({});

        if (inputRef.current.value !== url) {
          inputRef.current.value = url;
        }
      }
    }

    document.addEventListener('click', hidePanel);

    return () => document.removeEventListener('click', hidePanel);
  }, [isPanelActive]);

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  const {
    areScreensUnlimited,
    isScreensLimitReached,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
  } = userLimitsAndStats;

  async function handleSubmit(event) {
    event.preventDefault();

    setIsCreateScreensBtnDisabled(true);

    inputRef.current.blur();
    const url = inputRef.current.value;
    const isUrlValid = validateURL(url);
    const isResolutionSelected = resolutions.some(({ checked }) => checked);
    const checkedConfigsCheckboxes = checkboxes.filter((item) => item.checked);
    const areConfigsNotChecked = checkedConfigsCheckboxes.length === 0;

    dispatch(uiActions.setIsURLValid(isUrlValid));
    dispatch(uiActions.setIsWidthSelected(isResolutionSelected));
    setShowConfigNotCheckedError(areConfigsNotChecked);

    if (!isUrlValid || !isResolutionSelected || areConfigsNotChecked) {
      setIsCreateScreensBtnDisabled(false);
      return;
    }

    // check if the user has reached the limit of screenshots per month
    if (isScreensLimitReached && !areScreensUnlimited) {
      dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
      setIsCreateScreensBtnDisabled(false);
      return;
    }

    if (!areScreensUnlimited) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;
      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: true,
      };
      const canBeChecked = checkIfCurrentConfigsCanBeChecked(params);

      if (!canBeChecked) {
        setIsCreateScreensBtnDisabled(false);
        return;
      }
    }

    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};
    let isParallelSessionsLimitReached;

    try {
      const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
      isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
    } catch (error) {
      console.log('error of getting parallel sessions limit', error);
    }

    if (isParallelSessionsLimitReached) {
      dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));

      const params = { userId, licenseProduct, billingOperator, lastOrderReference };

      socket.emit('getUserData', params);

      setIsCreateScreensBtnDisabled(false);
      return;
    }

    let sessionId;

    try {
      const request = await api.getScreensSessionId();
      sessionId = request?.data;
      dispatch(uiActions.setScreensSessionId(sessionId));
    } catch (error) {
      console.log('error of getting sessionId', error);
    }

    // create array of of values of checked resolutions
    const checkedResolutions: number[] = resolutions.reduce((accum, { value, checked }) => {
      if (checked) accum.push(value);

      return accum;
    }, []);

    const configs = createScreensConfig(resolutions, checkedConfigsCheckboxes);
    const { user_auth, user_pass } = credentialsForBasicAuth;

    dispatch(uiActions.setScreenNumber(0));
    dispatch(uiActions.changeURL(url));
    dispatch(screensActions.fillScreens(configs));
    dispatch(resolutionsActions.addSubmittedResolutions(checkedResolutions));
    if (selectionStatus) dispatch(uiActions.toggleSelectionStatus(false));
    setIsPanelActive(false);
    setConfigDesktopWithUrlStyle({});
    sendScreensToServer({
      url,
      socket,
      configs,
      userId,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
    setIsCreateScreensBtnDisabled(false);
  }

  function handleFocus() {
    if (!isPanelActive) {
      let top, left, style;

      if (window.innerWidth < 992) {
        top = formRef.current.getBoundingClientRect().top;
        left = (window.innerWidth - 750) / 2;
        style = { position: 'fixed' };
      } else {
        top = formRef.current.offsetTop;
        left = formRef.current.offsetLeft;
      }

      style = { ...style, top, left };

      setConfigDesktopWithUrlStyle(style);
      setIsPanelActive(true);

      inputRef.current.select();
    }
  }

  return (
    <div className="config-desktop-with-URL">
      <div className="container">
        <div className="config-desktop-with-URL__container">
          <p className="config-desktop-with-URL__label">{testingURL}</p>
          <form
            className={urlPanelClasses}
            onSubmit={handleSubmit}
            style={configDesktopWithUrlStyle}
            ref={formRef}
            noValidate
          >
            <input
              type="url"
              name="url"
              placeholder={pleaseEnterYourURL}
              autoComplete="off"
              title={changeTestedURL}
              ref={inputRef}
              className={inputClasses}
              defaultValue={url}
              onFocus={handleFocus}
            />
            <p className={validationClasses}>{pleaseEnterAValidURL}</p>
            {isPanelActive && (
              <>
                <ConfigsAuthentication
                  username={username}
                  setUsername={setUsername}
                  password={password}
                  setPassword={setPassword}
                  isButtonDisabled={isButtonDisabled}
                  setIsButtonDisabled={setIsButtonDisabled}
                  areCredentialsConfirmed={areCredentialsConfirmed}
                  setAreCredentialsConfirmed={setAreCredentialsConfirmed}
                />
                <Resolutions />
                <div className="configurations">
                  <TableConfigsDesktop
                    checkboxes={checkboxes}
                    mayBeAlreadyChecked
                    checkBrowserGroupConfigs={checkBrowserGroupConfigs}
                    checkOSGroupConfigs={checkOSGroupConfigs}
                    checkAllConfigs={checkAllConfigs}
                    buttonLabel={createScreens}
                    isBtnDisabled={isCreateScreensBtnDisabled}
                  >
                    <p className={isCheckedClasses}>{pleaseCheckAnyConfiguration}</p>
                  </TableConfigsDesktop>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ConfigDesktopWithURL);
