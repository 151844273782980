import React from 'react';

import configurationsIcon from './configurations-icon.svg';
import fixedResolutionIcon from './fixed-resolution-icon.svg';

export function CompareIcon() {
  return (
    <svg
      className="mass-action__compare-icon"
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="mass-action__compare-icon-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 16C8.95 16 8.5 15.55 8.5 15V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V15C10.5 15.55 10.05 16 9.5 16ZM6.5 12H2.29C2.13 12 2 11.87 2 11.71V4.32C2 4.16 2.13 4.03 2.29 4.03H6.48C7.04 4.03 7.5 3.58 7.5 3.01C7.5 2.45 7.05 2 6.48 2H1C0.45 2 0 2.45 0 3V13C0 13.55 0.45 14 1 14H6.5C7.05 14 7.5 13.55 7.5 13C7.5 12.45 7.05 12 6.5 12ZM16.97 4.29C16.97 4.13 16.84 4 16.68 4H16.5C15.95 4 15.5 3.55 15.5 3C15.5 2.45 15.95 2 16.5 2H18C18.55 2 19 2.45 19 3V4.48C19 5.05 18.55 5.5 17.99 5.51C17.42 5.51 16.97 5.05 16.97 4.49V4.29ZM17.0002 11.6798C17.0002 11.8398 16.8702 11.9698 16.7102 11.9698H16.5102C15.9502 11.9698 15.4902 12.4198 15.4902 12.9898C15.4902 13.5498 15.9402 14.0098 16.5102 14.0098H18.0002C18.5502 14.0098 19.0002 13.5598 19.0002 13.0098V11.5098C19.0002 10.9598 18.5502 10.5098 18.0002 10.5098C17.4502 10.5098 17.0002 10.9598 17.0002 11.5098V11.6798ZM17 8.5C17 9.05 17.45 9.5 18 9.5C18.55 9.5 19 9.05 19 8.5V7.5C19 6.95 18.55 6.5 18 6.5C17.45 6.5 17 6.95 17 7.5V8.5ZM11.5 3C11.5 2.45 11.95 2 12.5 2H13.5C14.05 2 14.5 2.45 14.5 3C14.5 3.55 14.05 4 13.5 4H12.5C11.95 4 11.5 3.55 11.5 3ZM12.5 12C11.95 12 11.5 12.45 11.5 13C11.5 13.55 11.95 14 12.5 14H13.5C14.05 14 14.5 13.55 14.5 13C14.5 12.45 14.05 12 13.5 12H12.5Z"
        fill="white"
      />
    </svg>
  );
}

export default { configurationsIcon, fixedResolutionIcon };
