// core
import React from 'react';
import { components } from 'react-select';

const { SingleValue } = components;

function CustomSingleValue(props) {
  const { value, hasPixels } = props.data;
  let label;

  if (hasPixels) label = value;
  else label = `${value}`.toUpperCase();

  return (
    <SingleValue {...props}>
      {label}
      {hasPixels && <span className="filter__select__single-value-pixel">px</span>}
    </SingleValue>
  );
}

export const selectComponents = {
  SingleValue: CustomSingleValue,
  IndicatorSeparator: () => null,
};
