// core
import { Power2 } from 'gsap/TweenMax';
import { Draggable } from 'gsap/Draggable';
import { TweenLite } from 'gsap/TweenLite';

export class DraggableSlider {
  sliderWidth: number;
  slider: HTMLElement;

  constructor(slider, sliderWidth) {
    this.slider = slider;
    this.sliderWidth = sliderWidth;
    this.draggable = null;
    this.createDraggable();
  }

  createDraggable() {
    const slider = this.slider;
    const sliderWidth = this.sliderWidth;

    this.draggable = Draggable.create(this.slider, {
      type: 'x',
      edgeResistance: 1,
      bounds: {
        minX: this.convertPositive(sliderWidth - window.innerWidth),
        maxX: 0,
      },
      onPress() {
        this.offset = this.x - slider._gsTransform.x;
        TweenLite.killTweensOf(slider);
      },
      onDrag() {
        TweenLite.to(slider, 0.8, {
          x: this.x - this.offset,
          ease: Power2.easeOut,
        });
      },
    })[0];
  }

  // function for setting value of how far the tabs can be dragged,
  // if x is positive, the tabs will not be dragged
  convertPositive(x: number): number {
    if (x > 0) {
      x = x * -1 - 26;
    } else {
      x = 0;
    }

    return x;
  }

  destroy() {
    this.draggable.kill();
  }
}
