// core
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// components
import Tabs from '../tabs';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import { handleSubmitConfigNoURL, checkIfCurrentConfigsCanBeChecked } from '../../helpers';
import useTranslations from '../../hooks/useTranslations';
//default settings
import { app_metadata_namespace } from '../../settings.json';
// socket
import { _socket as socket } from '../../socket';
// typescript types
import { RootState } from '../../store/rootReducer';

function ConfigMobileNoURL(props) {
  // hooks
  const dispatch = useDispatch();
  const url = useSelector((state: RootState) => state.ui.url);
  const filter = useSelector((state: RootState) => state.resolutions.filter);
  const screens = useSelector((state: RootState) => state.screens);
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesNoURL);
  const submittedResolutions = useSelector(
    (state: RootState) => state.resolutions.submittedResolutions
  );
  const credentials = useSelector((state: RootState) => state.ui.credentialsForBasicAuth);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const { addScreens } = useTranslations();
  const { user } = useAuth0();

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  // number of checked configs
  const numberOfCheckedConfigs = checkboxes.filter((item) => item.checked).length;

  // define current resolution
  const currentResolution: number | number[] = filter
    ? filter
    : submittedResolutions.length === 1
    ? submittedResolutions[0]
    : submittedResolutions;
  const resolutionsToDisplay = Array.isArray(currentResolution)
    ? currentResolution.join(' px, ')
    : currentResolution;

  function handleSubmit(event) {
    event.preventDefault();

    const userId = user?.sub;
    const { user_auth, user_pass } = credentials;
    const {
      areScreensUnlimited,
      license_subscription_screens_count: screensLimit,
      count_screens_for_period: screensCount,
    } = userLimitsAndStats;

    if (!areScreensUnlimited) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;
      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: false,
      };
      const canBeChecked = checkIfCurrentConfigsCanBeChecked(params);

      if (!canBeChecked) return;
    }

    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    handleSubmitConfigNoURL(
      url,
      socket,
      checkboxes,
      dispatch,
      screensActions,
      configsCheckboxesWithURLActions,
      currentResolution,
      screens,
      userId,
      user_auth,
      user_pass,
      loginProfileId,
      props.screensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn
    );

    dispatch(uiActions.hideConfigMobileNoURL());
    dispatch(uiActions.hideHeaderCloseButton());

    document.body.style.overflow = ''; //enable scroll
  }

  return (
    <form className="configurations-mobile" onSubmit={handleSubmit}>
      <p className="configurations-mobile__width">
        Screen width:{' '}
        <span className="configurations-mobile__width-black">{`${resolutionsToDisplay} px`}</span>
      </p>
      <Tabs checkboxes={checkboxes} numberOfCheckedConfigs={numberOfCheckedConfigs} type="noURL" />
      <div className="tabs__btn">
        <button type="submit" className="btn blue-btn tabs__btn-item">
          {addScreens}
        </button>
      </div>
    </form>
  );
}

export default React.memo(ConfigMobileNoURL);
