// core
import React, { useState, useEffect, useRef, ReactElement, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import Switch from 'react-switch';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { loginProfilesActions } from '../../store/domains/loginProfiles/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
import { configsCheckboxesNoURLActions } from '../../store/domains/configsCheckboxesNoURL/actions';
// components
import LoginProfile from './components/loginProfile';
// instruments
import { disableScroll } from '../../helpers';
import { DraggableSlider } from '../../shared';
// images
import Icons from '../../images';
import { QuestionMark, QuestionMarkMobile } from './components/images';
// styles
import './configsAuthentication.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

const browsersToDisableForBasicAuth = ['safari' /*, 'internet explorer', 'opera'*/];
//const browsersToDisableForSmartScroll = ['internet explorer'];

function ConfigsAuthentication({ username, setUsername, password, setPassword }): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const [isFormBaseAuthActive, setIsFormBaseAuthActive] = useState(false);
  const [isFormLoginProfileActive, setIsFormLoginProfileActive] = useState(false);
  const [isLoginProfileSelected, setIsLoginProfileSelected] = useState(false);
  const [questionMarkActive, setQuestionMarkActive] = useState(false);
  const configsAuthenticationWrapperRef = useRef(null);
  const basicAuthBtnRef = useRef(null);
  const basicAuthFormRef = useRef(null);
  const loginProfileFormRef = useRef(null);
  const loginProfileBtnRef = useRef(null);
  const questionMarkTooltipRef = useRef(null);
  const smartscrollCheckerRef = useRef(null);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesWithURL);
  const areCredentialsForBasicAuthConfirmed = useSelector(
    (state: RootState) => state.ui.areCredentialsForBasicAuthConfirmed
  );
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px

  // if mobile device - make "smart scroll" panel draggable
  useEffect(() => {
    if (!isDesktop) {
      const width =
        smartscrollCheckerRef.current.offsetWidth +
        basicAuthBtnRef.current.offsetWidth +
        loginProfileBtnRef.current.offsetWidth +
        200;

      new DraggableSlider(configsAuthenticationWrapperRef.current, width);
    }
  }, [areCredentialsForBasicAuthConfirmed, isLoginProfileSelected]);

  // if "smart scroll" is checked - disable IE checkboxes
  // useEffect(() => {
  //   if (isSmartScrollOn && !areCredentialsForBasicAuthConfirmed) {
  //     const configsToUncheck = checkboxes
  //       .filter(({ browser, checked }) => checked && browser === 'internet explorer')
  //       .map(({ id }) => id);

  //     dispatch(configsCheckboxesWithURLActions.uncheckConfigs(configsToUncheck));
  //     dispatch(configsCheckboxesWithURLActions.disableConfigs(browsersToDisableForSmartScroll));
  //     dispatch(configsCheckboxesNoURLActions.disableConfigs(browsersToDisableForSmartScroll));
  //   } else if (!isSmartScrollOn && !areCredentialsForBasicAuthConfirmed) {
  //     dispatch(configsCheckboxesWithURLActions.enableConfigs(browsersToDisableForSmartScroll));
  //     dispatch(configsCheckboxesNoURLActions.enableConfigs(browsersToDisableForSmartScroll));
  //   }
  // }, [isSmartScrollOn]);

  useEffect(() => {
    if (areCredentialsForBasicAuthConfirmed) {
      const configsToUncheck = checkboxes
        .filter(({ browser, checked }) => checked && browser !== 'chrome' && browser !== 'firefox')
        .map(({ id }) => id);

      dispatch(configsCheckboxesWithURLActions.uncheckConfigs(configsToUncheck));
      dispatch(configsCheckboxesWithURLActions.disableConfigs(browsersToDisableForBasicAuth));
      dispatch(configsCheckboxesNoURLActions.disableConfigs(browsersToDisableForBasicAuth));
    } else if (!areCredentialsForBasicAuthConfirmed && !isSmartScrollOn) {
      dispatch(configsCheckboxesWithURLActions.enableConfigs(browsersToDisableForBasicAuth));
      dispatch(configsCheckboxesNoURLActions.enableConfigs(browsersToDisableForBasicAuth));
    } /*else if (!areCredentialsForBasicAuthConfirmed && isSmartScrollOn) {
      // if isSmartScrollOn is true we must not enable 'internet explorer' checkboxes
      const browsersToEnable = browsersToDisableForBasicAuth.filter(
        (browser) => browser !== 'internet explorer'
      );

      dispatch(configsCheckboxesWithURLActions.enableConfigs(browsersToEnable));
      dispatch(configsCheckboxesNoURLActions.enableConfigs(browsersToEnable));
    }*/
  }, [areCredentialsForBasicAuthConfirmed]);

  // is any login profiles selected
  useEffect(() => {
    const isAnyLoginProfileSelected = profiles.some((profile) => profile.selected);

    setIsLoginProfileSelected(isAnyLoginProfileSelected);
  }, [profiles]);

  useEffect(() => {
    function hideTooltip(event: MouseEvent) {
      if (
        questionMarkActive &&
        questionMarkTooltipRef.current &&
        !questionMarkTooltipRef.current.contains(event.target)
      ) {
        setQuestionMarkActive((prevState) => !prevState);
      }
    }

    document.addEventListener('click', hideTooltip);

    return () => document.removeEventListener('click', hideTooltip);
  }, [questionMarkActive]);

  useEffect(() => {
    if (isDesktop) {
      function hideFormBaseAuth(event: MouseEvent) {
        if (isFormBaseAuthActive && !basicAuthFormRef.current.contains(event.target)) {
          setIsFormBaseAuthActive(false);
        }
      }

      document.addEventListener('click', hideFormBaseAuth);

      return () => document.removeEventListener('click', hideFormBaseAuth);
    }
  }, [isFormBaseAuthActive]);

  useEffect(() => {
    if (isDesktop) {
      function hideFormLoginProfile(event: MouseEvent) {
        if (
          isFormLoginProfileActive &&
          !loginProfileFormRef.current.contains(event.target) &&
          event.target.className.indexOf('login-profile-creator-popup') === -1 &&
          event.target.className.indexOf('select') === -1 &&
          event.target.className.indexOf('dialog') === -1
        ) {
          setIsFormLoginProfileActive(false);
        }
      }

      document.addEventListener('click', hideFormLoginProfile);

      return () => document.removeEventListener('click', hideFormLoginProfile);
    }
  }, [isFormLoginProfileActive]);

  // compute CSS classes
  const formsWrapper = cx('configs-authentication__forms-wrapper', {
    active: isFormBaseAuthActive || isFormLoginProfileActive,
  });
  const basicAuthenticationButtonClasses = cx(
    'configs-authentication__basic-authentication-button',
    { active: areCredentialsForBasicAuthConfirmed }
  );
  const basicAuthenticationFormClasses = cx('configs-authentication__basic-authentication-form', {
    active: isFormBaseAuthActive,
  });
  const loginProfileFormClasses = cx('configs-authentication__login-profile-form', {
    active: isFormLoginProfileActive,
  });
  const loginProfileButtonClasses = cx('configs-authentication__login-profile-button', {
    active: isLoginProfileSelected,
  });

  // HTML elements styles
  const basicAuthFormStyle = { left: basicAuthBtnRef.current?.offsetLeft };
  const questionMarkStyle = isDesktop
    ? {}
    : questionMarkActive
    ? { background: '#343e4c', borderColor: '#343e4c' }
    : {};

  function checkIfButtonMustBeDisabled(username: string, password: string) {
    if (!username || !password) {
      dispatch(uiActions.toggleAreCredentialsForBasicAuthConfirmed(false));

      return;
    }

    if (username && password && areCredentialsForBasicAuthConfirmed) {
      const payload = { user_auth: undefined, user_pass: undefined };

      dispatch(uiActions.toggleAreCredentialsForBasicAuthConfirmed(false));
      dispatch(uiActions.setCredentialsForBasicAuth(payload));
    }
  }

  function loginProfileBtnClickHandler() {
    if (profiles.length === 0) {
      const operationWithProfileData = { operation: 'create', profileID: null };
      dispatch(loginProfilesActions.markProfileForEditing(operationWithProfileData));

      if (isDesktop) {
        dispatch(uiActions.toggleLoginProfileCreatorPopup(true));
      } else {
        dispatch(uiActions.toggleLoginProfileCreatorPopupMobile(true));
        disableScroll(true);
      }
    } else {
      setIsFormLoginProfileActive((prevState) => !prevState);

      if (!isFormLoginProfileActive && isFormBaseAuthActive) {
        setIsFormBaseAuthActive(false);
      }
    }
  }

  function useBtnClickHandler() {
    dispatch(uiActions.toggleAreCredentialsForBasicAuthConfirmed(true));
    dispatch(uiActions.setCredentialsForBasicAuth({ user_auth: username, user_pass: password }));
    setIsFormBaseAuthActive(false);
  }

  function newProfileBtnClickHandler() {
    const operationWithProfileData = { operation: 'create', profileID: null };

    dispatch(loginProfilesActions.markProfileForEditing(operationWithProfileData));

    if (isDesktop) dispatch(uiActions.toggleLoginProfileCreatorPopup(true));
    else {
      dispatch(uiActions.toggleLoginProfileCreatorPopupMobile(true));
      disableScroll(true);
    }
  }

  function usernameChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    setUsername(event.target.value);

    checkIfButtonMustBeDisabled(event.target.value, password);
  }

  function passwordChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);

    checkIfButtonMustBeDisabled(username, event.target.value);
  }

  function basicAuthCloseClickHandler(event) {
    event.stopPropagation();

    dispatch(uiActions.toggleAreCredentialsForBasicAuthConfirmed(false));
    dispatch(uiActions.setCredentialsForBasicAuth({ user_auth: undefined, user_pass: undefined }));
  }

  function loginPrrofileCloseClickHandler(event) {
    event.stopPropagation();

    dispatch(loginProfilesActions.unselectProfile());
  }

  function basicAuthenticationBtnClickHandler() {
    setIsFormBaseAuthActive((prevState) => !prevState);
    if (!isFormBaseAuthActive && isFormLoginProfileActive) setIsFormLoginProfileActive(false);
  }

  function onSwitchChange(checked: boolean) {
    dispatch(uiActions.toggleSmartScrollSwitch(checked));
  }

  function handleQuestionClick() {
    setQuestionMarkActive((prevState) => !prevState);
  }

  function handleSmartscrollCheckerLabelClick() {
    dispatch(uiActions.toggleSmartScrollSwitch(!isSmartScrollOn));
  }

  return (
    <div className="configs-authentication">
      <div
        className="configs-authentication__buttons-wrapper"
        ref={configsAuthenticationWrapperRef}
      >
        <div className="configs-authentication__smartscroll" ref={smartscrollCheckerRef}>
          <div className="configs-authentication__smartscroll-checker">
            <Switch
              handleDiameter={12}
              onColor="#3DC3E2"
              offColor="#DDE0E7"
              offHandleColor="#FFF"
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={onSwitchChange}
              checked={isSmartScrollOn}
              height={17}
              width={isDesktop ? 28 : 32}
              className="configs-authentication__smartscroll-checker-switch"
            />
            <span
              className="configs-authentication__smartscroll-checker-label"
              onClick={handleSmartscrollCheckerLabelClick}
            >
              Smartscroll
            </span>
          </div>
          <button
            type="button"
            className="configs-authentication__smartscroll-question"
            style={questionMarkStyle}
            onClick={handleQuestionClick}
          >
            {isDesktop ? <QuestionMark /> : <QuestionMarkMobile />}
            {questionMarkActive && (
              <p
                className="configs-authentication__smartscroll-question-tooltip"
                ref={questionMarkTooltipRef}
              >
                Smartscroll removes all elements from screenshots with position: fixed and sticky
                such as pop-up ads, GDPR notification window, sticky header, etc.
              </p>
            )}
          </button>
        </div>
        <div
          className={basicAuthenticationButtonClasses}
          onClick={basicAuthenticationBtnClickHandler}
          tabIndex={0}
          role="button"
          aria-pressed="false"
          ref={basicAuthBtnRef}
        >
          {areCredentialsForBasicAuthConfirmed && <Icons.checkIcon />}
          Basic Authentication
          {areCredentialsForBasicAuthConfirmed && (
            <button
              type="button"
              className="configs-authentication__basic-authentication-button-close-btn"
              onClick={basicAuthCloseClickHandler}
            >
              <Icons.cross />
            </button>
          )}
        </div>
        <div
          className={loginProfileButtonClasses}
          onClick={loginProfileBtnClickHandler}
          ref={loginProfileBtnRef}
          tabIndex={0}
          role="button"
          aria-pressed="false"
        >
          {isLoginProfileSelected && <Icons.checkIcon />}
          Login Profile
          {isLoginProfileSelected && (
            <button
              type="button"
              className="configs-authentication__login-profile-button-close-btn"
              onClick={loginPrrofileCloseClickHandler}
            >
              <Icons.cross />
            </button>
          )}
        </div>
      </div>
      <div className={formsWrapper}>
        <div
          className={basicAuthenticationFormClasses}
          style={basicAuthFormStyle}
          ref={basicAuthFormRef}
        >
          <div className="configs-authentication__basic-authentication-form-info">
            <Icons.infoIcon />
            <p className="configs-authentication__basic-authentication-form-info-text">
              Firefox and Chrome only
            </p>
          </div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={usernameChangeHandler}
            className="configs-authentication__basic-authentication-form-username"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordChangeHandler}
            className="configs-authentication__basic-authentication-form-password"
          />
          <a
            href="https://howto.comparium.app/guides/insides-of-the-web-app/basic-authentication/"
            className="configs-authentication__basic-authentication-form-link"
          >
            About
          </a>
          <button
            type="button"
            className="configs-authentication__basic-authentication-form-btn btn blue-btn"
            disabled={!username || !password || areCredentialsForBasicAuthConfirmed}
            onClick={useBtnClickHandler}
          >
            Save
          </button>
        </div>
        <div
          className={loginProfileFormClasses}
          ref={loginProfileFormRef}
          style={{ left: loginProfileBtnRef.current?.offsetLeft }}
        >
          <ul className="configs-authentication__login-profile-list">
            {profiles.map((item) => (
              <LoginProfile key={item.id} {...item} />
            ))}
          </ul>
          <a
            href="https://howto.comparium.app/configure-login-profile/"
            className="configs-authentication__login-profile-form-link"
          >
            About
          </a>
          <button
            type="button"
            className="configs-authentication__login-profile-form-btn blue-btn"
            onClick={newProfileBtnClickHandler}
          >
            <Icons.plusMini /> Add new Profile
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ConfigsAuthentication);
