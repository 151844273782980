// core
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// components
import Tabs from '../tabs';
import Resolutions from '../resolutions';
import ConfigsAuthentication from '../configsAuthentication';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { resolutionsActions } from '../../store/domains/resolutions/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import {
  validateURL,
  scrollToTop,
  sendScreensToServer,
  checkIfCurrentConfigsCanBeChecked,
  createScreensConfig,
} from '../../helpers';
import useTranslations from '../../hooks/useTranslations';
import { api } from '../../api';
//default settings
import { app_metadata_namespace } from '../../settings.json';
//socket
import { _socket as socket } from '../../socket';
// styles
import './configMobileWithURL.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function ConfigMobileWithURL() {
  // hooks
  const dispatch = useDispatch();
  const [showConfigNotCheckedError, setShowConfigNotCheckedError] = useState(false);
  const [height100, setHeight100] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCreateScreensBtnDisabled, setIsCreateScreensBtnDisabled] = useState(false);
  const [areCredentialsConfirmed, setAreCredentialsConfirmed] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const credentialsForBasicAuth = useSelector(
    (state: RootState) => state.ui.credentialsForBasicAuth
  );
  const isActive = useSelector((state: RootState) => state.ui.isConfigMobileWithURLActive);
  const tabsActive = useSelector((state: RootState) => state.ui.areTabsMobileWithURLActive);
  const isURLValid = useSelector((state: RootState) => state.ui.isURLValid);
  const url = useSelector((state: RootState) => state.ui.url);
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesWithURL);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const isConfigMobileNoURLActive = useSelector(
    (state: RootState) => state.ui.isConfigMobileNoURLActive
  );
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const { user } = useAuth0();
  const {
    pleaseEnterYourURL,
    pleaseEnterAValidURL,
    pleaseCheckAnyConfiguration,
    createScreens,
  } = useTranslations();

  // compute CSS classes
  const isCheckedClasses = cx('validation-message', { active: showConfigNotCheckedError });
  const tabsWrapperClasses = cx('tabs-wrapper', { active: tabsActive });
  const inputClasses = cx('config-mobile-with-URL__input', {
    invalid: !isURLValid,
  });
  const urlPanelClasses = cx(
    'config-mobile-with-URL',
    { active: isActive },
    { 'height-100': height100 },
    { 'd-none': isConfigMobileNoURLActive }
  );
  const validationMessageClasses = cx('validation-message', {
    active: !isURLValid,
  });

  // checked 'configs' checkboxes
  const checkedConfigsCheckboxes = checkboxes.filter((item) => item.checked);
  // number of checked configs
  const numberOfCheckedConfigs = checkedConfigsCheckboxes.length;

  useEffect(() => {
    function hidePanel(event) {
      if (height100 && formRef.current === event.target) {
        dispatch(uiActions.hideConfigMobileWithURL());
        setHeight100(false);
        scrollToTop();
      }
    }

    document.addEventListener('click', hidePanel);

    return () => document.removeEventListener('click', hidePanel);
  }, [height100]);

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  // check if the user has reached the limit of screenshots per month
  const {
    areScreensUnlimited,
    isScreensLimitReached,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
  } = userLimitsAndStats;

  async function handleSubmit(event) {
    event.preventDefault();

    setIsCreateScreensBtnDisabled(true);

    const isResolutionSelected = resolutions.some(({ checked }) => checked);
    const areConfigsNotChecked = numberOfCheckedConfigs === 0;

    dispatch(uiActions.setIsWidthSelected(isResolutionSelected));
    setShowConfigNotCheckedError(areConfigsNotChecked);

    if (!isResolutionSelected || areConfigsNotChecked) {
      setIsCreateScreensBtnDisabled(false);
      return;
    }

    if (isScreensLimitReached && !areScreensUnlimited) {
      dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
      setIsCreateScreensBtnDisabled(false);
      return;
    }

    if (!areScreensUnlimited) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;
      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: true,
      };
      const canBeChecked = checkIfCurrentConfigsCanBeChecked(params);

      if (!canBeChecked) {
        setIsCreateScreensBtnDisabled(false);
        return;
      }
    }

    const userId = user?.sub;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};
    let isParallelSessionsLimitReached;

    try {
      const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
      isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
    } catch (error) {
      console.log('error of getting parallel sessions limit', error);
    }

    if (isParallelSessionsLimitReached) {
      dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));

      const params = { userId, licenseProduct, billingOperator, lastOrderReference };

      socket.emit('getUserData', params);

      setIsCreateScreensBtnDisabled(false);
      return;
    }

    let sessionId;

    try {
      const request = await api.getScreensSessionId();
      sessionId = request?.data;
      dispatch(uiActions.setScreensSessionId(sessionId));
    } catch (error) {
      console.log('error of getting sessionId', error);
    }

    // create array of values of checked resolutions
    const checkedResolutions = resolutions.reduce((accum, { value, checked }) => {
      if (checked) accum.push(value);

      return accum;
    }, []);
    const url = inputRef.current.value;
    const configs = createScreensConfig(resolutions, checkedConfigsCheckboxes);
    const { user_auth, user_pass } = credentialsForBasicAuth;

    dispatch(uiActions.setScreenNumber(0));
    dispatch(screensActions.fillScreens(configs));
    dispatch(uiActions.changeURL(url));
    dispatch(resolutionsActions.addSubmittedResolutions(checkedResolutions));
    dispatch(uiActions.hideConfigMobileWithURL());
    dispatch(uiActions.hideTabsMobileWithURL());
    dispatch(uiActions.hideHeaderCloseButton());
    scrollToTop();
    sendScreensToServer({
      url,
      socket,
      configs,
      userId,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
    setIsCreateScreensBtnDisabled(false);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (validateURL(inputRef.current.value)) {
        dispatch(uiActions.setIsURLValid(true));
        dispatch(uiActions.showTabsMobileWithURL());
        dispatch(uiActions.showHeaderCloseButton());
        dispatch(uiActions.changeURL(inputRef.current.value));
        setHeight100(false);
        inputRef.current.blur();
      } else {
        dispatch(uiActions.setIsURLValid(false));
      }
    }
  }

  function handleFocus() {
    if (!isActive) {
      dispatch(uiActions.showConfigMobileWithURL());
      // work around because form opens too fast and after its opening we click on its overlay and its closes
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          setHeight100(true);
        });
      });
    }

    inputRef.current.select();
  }

  return (
    <form className={urlPanelClasses} onSubmit={handleSubmit} ref={formRef} noValidate>
      <div className="config-mobile-with-URL__wrapper">
        <input
          type="url"
          name="url"
          placeholder={pleaseEnterYourURL}
          autoComplete="off"
          ref={inputRef}
          className={inputClasses}
          defaultValue={url}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
        />
        <p className={validationMessageClasses}>{pleaseEnterAValidURL}</p>
      </div>
      {tabsActive && (
        <>
          <ConfigsAuthentication
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
            areCredentialsConfirmed={areCredentialsConfirmed}
            setAreCredentialsConfirmed={setAreCredentialsConfirmed}
          />
          <Resolutions />
          <div className={tabsWrapperClasses}>
            <p className={isCheckedClasses}>{pleaseCheckAnyConfiguration}</p>
            <Tabs
              checkboxes={checkboxes}
              numberOfCheckedConfigs={numberOfCheckedConfigs}
              type="withURL"
            />
            <div className="tabs__btn">
              <button
                type="submit"
                className="btn blue-btn tabs__btn-item"
                disabled={isCreateScreensBtnDisabled}
              >
                {createScreens}
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  );
}

export default React.memo(ConfigMobileWithURL);
