// core
import React, { useState, useEffect, useRef, useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { resolutionsActions } from '../../store/domains/resolutions/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
// components
import Screenshot from '../screenshot';
import ConfigDesktopNoURL from '../configDesktopNoURL';
import ConfigMobileNoURL from '../configMobileNoURL';
import ConfigDesktopWithURL from '../configDesktopWithURL';
import ConfigMobileWithURL from '../configMobileWithURL';
import { selectComponents } from './components/customSelectComponents';
// instruments
import {
  getQueryVariables,
  scrollToTop,
  /*convertIntExpToie,*/
  sendScreensToServer,
  getConfigsCheckboxesAsStrings,
  isScreenAvailable,
  computeMainBlockHeightWithAlerts,
  dataLayerPush,
} from '../../helpers';
import { selectStyles } from './stylesAndSettings';
import { useAuth0 } from '../../helpers/auth';
import useTranslations from '../../hooks/useTranslations';
import { api } from '../../api';
//default settings
import { resolutions as availableResolutions, app_metadata_namespace } from '../../settings.json';
//socket
import { _socket } from '../../socket';
// images
import images, { CompareIcon } from './images';
import Icons from '../../images';
// styles
import './pageScreenshots.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

const PageScreenshots: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const selectionStatus = useSelector((state: RootState) => state.ui.massSelectionStatus);
  const url = useSelector((state: RootState) => state.ui.url);
  const screens = useSelector((state: RootState) => state.screens);
  const isURLValid = useSelector((state: RootState) => state.ui.isURLValid);
  const isConfigDesktopNoURLActive = useSelector(
    (state: RootState) => state.ui.isConfigDesktopNoURLActive
  );
  const isConfigMobileNoURLActive = useSelector(
    (state: RootState) => state.ui.isConfigMobileNoURLActive
  );
  const filter = useSelector((state: RootState) => state.resolutions.filter);
  const filteredScreens = useSelector((state: RootState) => state.resolutions.filteredScreens);
  const resolutions = useSelector((state: RootState) => state.resolutions.submittedResolutions);
  const areTabsMobileWithURLActive = useSelector(
    (state: RootState) => state.ui.areTabsMobileWithURLActive
  );
  const isThanksForPurchaseAlertActive = useSelector(
    (state: RootState) => state.ui.isThanksForPurchaseAlertActive
  );
  const isPlanActiveAlertActive = useSelector(
    (state: RootState) => state.ui.isPlanActiveAlertActive
  );
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesWithURL);
  const [isComparisonPopupActive, setIsComparisonPopupActive] = useState(false);
  const [filterOption, setFilterOption] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const screensSessionId = useSelector((state: RootState) => state.ui.screensSessionId); // id of the current screenshots session - we need it in order to add new screenshots for creation and not update stats of screens created per month
  const isPaymentDeatilsUpdatedAlertActive = useSelector(
    (state: RootState) => state.ui.isPaymentDeatilsUpdatedAlertActive
  );
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const credentialsForBasicAuth = useSelector(
    (state: RootState) => state.ui.credentialsForBasicAuth
  );
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const socket = useRef(_socket);
  const popupRef = useRef(null);
  const isXSMobile = useMediaQuery({ maxWidth: 576 }); // check if screen width is less than or equal to 576px
  const isMobile = useMediaQuery({ maxWidth: 768 }); // if screen width is less than or equal to 768px
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px
  const { width, addScreens, newConfiguration, addNewConfiguration } = useTranslations();
  const { user, isAppSumoAlertActive } = useAuth0();

  // compute CSS classes
  const thumbnailsClasses = cx('thumbnails', {
    'd-none': areTabsMobileWithURLActive || isConfigMobileNoURLActive,
  });
  const massActionClasses = cx('mass-action', {
    'd-none': isConfigMobileNoURLActive,
  });
  const filterClasses = cx('filter', { 'd-none': isConfigMobileNoURLActive });

  // count number of screens
  const screensCounter = filter ? filteredScreens.length : screens.length;

  // count number of checked screens
  const checkedScreensCounter = useMemo(
    () =>
      filter
        ? filteredScreens.filter((item) => item.checked).length
        : screens.filter((item) => item.checked).length,
    [filter, screens, filteredScreens]
  );

  // compute CSS height of 'main' block
  const style = computeMainBlockHeightWithAlerts({
    isXSMobile,
    isThanksForPurchaseAlertActive,
    isPlanActiveAlertActive,
    isPaymentDeatilsUpdatedAlertActive,
    isAppSumoAlertActive,
  });

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedLoginProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedLoginProfile?.id);
  }, [profiles]);

  // get configs from query (GET) parameters
  useEffect(() => {
    async function executeEffect() {
      const configsAsStrings = getConfigsCheckboxesAsStrings(checkboxes);
      const queryData = await getQueryVariables(configsAsStrings, availableResolutions);

      if (queryData) {
        let isParallelSessionsLimitReached;
        const userId = user?.sub;
        const {
          license_product: licenseProduct,
          billing_operator: billingOperator,
          last_order_reference: lastOrderReference,
        } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

        try {
          const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
          isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
        } catch (error) {
          console.log('error of getting parallel sessions limit', error);
        }

        if (isParallelSessionsLimitReached) {
          dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));

          const params = { userId, licenseProduct, billingOperator, lastOrderReference };

          socket.current.emit('getUserData', params);

          return;
        }

        const {
          areScreensUnlimited,
          license_subscription_screens_count: screensLimit,
          count_screens_for_period: screensCount,
        } = userLimitsAndStats;
        const { url, configs, resolutions, isQueryParamFromExtension } = queryData;

        if (!areScreensUnlimited) {
          const numberOfRequestedScreens = configs.length;
          const numberOfAllowedScreens = screensLimit - screensCount;

          if (numberOfAllowedScreens < numberOfRequestedScreens) {
            dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
            return;
          }
        }

        let sessionId;

        try {
          const request = await api.getScreensSessionId();
          sessionId = request?.data;
          dispatch(uiActions.setScreensSessionId(sessionId));
        } catch (error) {
          console.log('error of getting sessionId', error);
        }

        resolutions.forEach((resolution) => {
          const doesResolutionExist = availableResolutions.find(
            (availableResolution) => availableResolution === resolution
          );

          if (!doesResolutionExist) {
            dispatch(resolutionsActions.addResolution(resolution));
          }
        });

        dispatch(uiActions.changeURL(url));
        dispatch(screensActions.fillScreens(configs));
        dispatch(configsCheckboxesWithURLActions.checkConfigs(configs));
        dispatch(resolutionsActions.checkResolutions(resolutions));
        dispatch(resolutionsActions.addSubmittedResolutions(resolutions));

        sendScreensToServer({
          url,
          socket: socket.current,
          configs,
          userId,
          sessionId,
          licenseProduct,
          billingOperator,
          lastOrderReference,
        });

        // if URL includes query parameter create_screens_from_browser_extension,
        // we send info about screens to GTM and mark them as screens requested from
        // browser extension
        // we send info to GTM asynchronously in order not to block application
        if (isQueryParamFromExtension) {
          setTimeout(() => configs.forEach((config) => sendDataAboutScreensToGTM(config)), 1000);
        }
      }
    }

    executeEffect();
  }, []);

  // filter screens on change of resolutions select
  useEffect(() => {
    if (filter) {
      const filtered = screens.filter((screen) => screen.resolution === filter);

      dispatch(resolutionsActions.fillFilteredScreens(filtered));
    } else if (filteredScreens.length !== 0) {
      dispatch(resolutionsActions.fillFilteredScreens([]));
    }
  }, [filter, screens]);

  // disable "mass selection" and uncheck all screens on filter change
  useEffect(() => {
    if (selectionStatus) {
      dispatch(uiActions.toggleSelectionStatus(false));
      dispatch(screensActions.uncheckAllScreens());
    }
  }, [filter]);

  // disable "mass selection" if the last screen was unchecked
  useEffect(() => {
    if (selectionStatus) {
      if (screens.filter((item) => item.checked).length === 0) {
        dispatch(uiActions.toggleSelectionStatus(false));
        dispatch(screensActions.uncheckAllScreens());
      }
    }
  }, [screens]);

  // change URL in the browser address bar after each change in the screens list
  // so after the page reload users can see the screens according to the last
  // screens configursation they selected
  useEffect(() => {
    let newUrl: string;

    if (screens.length > 0) {
      const queryBrowsers = screens.reduce((accum, { os, browser, version, resolution }) => {
        //const modifiedBrowser = convertIntExpToie(browser);

        return `${accum}&browser=${os}_${browser}_${version}_${resolution}`;
      }, '');

      newUrl = `?url=${url}${queryBrowsers}`;
    } else {
      newUrl = '/';
    }

    window.history.replaceState('', '', newUrl);
  }, [screens]);

  // hide popup on click on overlay
  useEffect(() => {
    function hidePopup(event) {
      if (isComparisonPopupActive && !popupRef?.current?.contains(event.target)) {
        setIsComparisonPopupActive(false);
      }
    }

    document.addEventListener('click', hidePopup);

    return () => document.removeEventListener('click', hidePopup);
  }, [isComparisonPopupActive]);

  // function for creating screen JSX
  function createScreen(item, index) {
    return (
      <Screenshot
        key={`${item.os}_${item.browser}_${item.version}_${item.resolution}`}
        number={index}
        status={isScreenAvailable(item.screen)}
        isSmartScrollOn={isSmartScrollOn}
        {...item}
      />
    );
  }

  // screens JSX
  const screensJSX = useMemo(
    () => (filter ? filteredScreens.map(createScreen) : screens.map(createScreen)),
    [filter, screens, filteredScreens]
  );

  // check if number of screens is equal to number of configs
  const displayBtn = screens.length < checkboxes.length * resolutions.length;

  // set options for 'filter' select
  useEffect(() => {
    let filterOptions = resolutions.map((item) => ({
      value: item,
      label: `${item} px`,
      hasPixels: true,
    }));

    filterOptions = [{ value: 'all', label: 'all', hasPixels: false }, ...filterOptions];

    if (!resolutions.find((item) => item.number === filter))
      dispatch(resolutionsActions.setFilter('all'));

    setFilterOptions(filterOptions);
    setFilterOption(filterOptions[0]);
  }, [resolutions]);

  // onChange handler of 'resolutions' select filter
  function handleChange(filterOption) {
    setFilterOption(filterOption);

    if (filterOption.value === 'all') {
      dispatch(resolutionsActions.setFilter(filterOption.value));
    } else {
      dispatch(uiActions.setScreenNumber(0));
      dispatch(resolutionsActions.setFilter(filterOption.value));
    }
  }

  const selectJSX =
    resolutions.length > 1 ? (
      <Select
        className="filter__select"
        classNamePrefix="filter__select"
        defaultValue={{ value: 'ALL', label: 'ALL' }}
        value={filterOption}
        options={filterOptions}
        isSearchable={false}
        onChange={handleChange}
        styles={selectStyles}
        components={selectComponents}
      />
    ) : resolutions.length === 1 ? (
      `${resolutions[0]} px`
    ) : null;

  function showConfigMobileNoURL() {
    if (url.length > 0 && isURLValid) {
      scrollToTop();
      dispatch(uiActions.showHeaderCloseButton());
      dispatch(uiActions.showConfigMobileNoURL());

      return;
    }

    isURLValid && dispatch(uiActions.setIsURLValid(false));
  }

  function selectAll() {
    dispatch(uiActions.toggleSelectionStatus(true));
    dispatch(screensActions.checkAllScreens(filter));
  }

  function deselectAll() {
    dispatch(uiActions.toggleSelectionStatus(false));
    dispatch(screensActions.uncheckAllScreens());
  }

  function updateScreens() {
    const {
      areScreensUnlimited,
      isScreensLimitReached,
      license_subscription_screens_count: screensLimit,
      count_screens_for_period: screensCount,
    } = userLimitsAndStats;
    const checkedScreens = screens.filter((item) => item.checked);
    const numberOfAllowedScreens = screensLimit - screensCount;
    const numberOfExpectedScreens = checkedScreens.length;

    // check if the user does not have unlimitted screens,
    // if he has reached the limit of screenshots per month, and
    // if the number of checked screens is not greater than number of allowed screens
    if (
      !areScreensUnlimited &&
      (isScreensLimitReached || numberOfAllowedScreens < numberOfExpectedScreens)
    ) {
      dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
      return;
    }

    const uncheckedScreens = screens.filter((item) => !item.checked);
    const userId = user?.sub;
    const { user_auth, user_pass } = credentialsForBasicAuth;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    const payload = { checked: checkedScreens, unchecked: uncheckedScreens };
    dispatch(screensActions.updateCheckedScreens(payload));

    sendScreensToServer({
      url,
      socket: socket.current,
      configs: checkedScreens,
      userId,
      noCache: true,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId: screensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
  }

  function deleteScreens() {
    dispatch(uiActions.setScreenNumber(0));
    dispatch(uiActions.showDialog());
  }

  function compareScreens() {
    if (screens.length <= 1) setIsComparisonPopupActive(true);
    else dispatch(uiActions.toggleCompare(true));
  }

  function compareScreensMobile() {
    if (screens.length <= 1) setIsComparisonPopupActive(true);
    else dispatch(uiActions.toggleCompareMobile(true));
  }

  function handleClose() {
    setIsComparisonPopupActive(false);
  }

  function handleClick() {
    if (url.length > 0 && isURLValid) {
      dispatch(uiActions.toggleConfigDesktopNoURL());

      return;
    }

    isURLValid && dispatch(uiActions.setIsURLValid(false));
  }

  return (
    <main className="main" style={style}>
      {isDesktop && <ConfigDesktopWithURL />}
      {isMobile && <ConfigMobileWithURL />}
      <div className={filterClasses}>
        <div className="container">
          <div className="filter__container">
            <img src={images.fixedResolutionIcon} alt="" className="filter__img" />
            {width} {selectJSX}
          </div>
        </div>
      </div>
      <div className={massActionClasses}>
        <div className="container">
          <div className="mass-action__container">
            {checkedScreensCounter === 0 ? (
              <button
                type="button"
                className="mass-action__select-all"
                onClick={selectAll}
                title="Select all"
              >
                <span className="mass-action__select-all-counter">{screensCounter}</span>
                {screensCounter !== 0 && (
                  <span className="mass-action__select-all-label">Select all</span>
                )}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="mass-action__deselect-all"
                  onClick={deselectAll}
                  title="Deselect all"
                >
                  <span className="mass-action__deselect-all-counter">
                    {checkedScreensCounter === screensCounter
                      ? screensCounter
                      : `${checkedScreensCounter}(${screensCounter})`}{' '}
                    Selected
                  </span>
                  <span className="mass-action__deselect-all-label">Deselect all</span>
                </button>
                <button
                  type="button"
                  className="mass-action__update"
                  onClick={updateScreens}
                  title="Update"
                >
                  <Icons.updateMiniWhite />
                  <span className="mass-action__update-label">Update</span>
                </button>
                <button
                  type="button"
                  className="mass-action__delete"
                  onClick={deleteScreens}
                  title="Delete"
                >
                  <Icons.deleteMiniWhite />
                  <span className="mass-action__delete-label">Delete</span>
                </button>
              </>
            )}
            {isDesktop && (
              <button className="mass-action__compare blue-btn" onClick={compareScreens}>
                <CompareIcon /> compare
              </button>
            )}
            {isMobile && !selectionStatus && (
              <button className="mass-action__compare blue-btn" onClick={compareScreensMobile}>
                <CompareIcon /> compare
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={thumbnailsClasses}>
        <div className="container">
          <div className="thumbnails__container">
            {screensJSX}
            {displayBtn && (
              <>
                {isDesktop && (
                  <div className="new-configuration">
                    <button
                      className="new-configuration__button"
                      onClick={handleClick}
                      title={addNewConfiguration}
                    >
                      <Icons.plus />
                      <span className="new-configuration__button-label">{newConfiguration}</span>
                    </button>
                  </div>
                )}
                {isMobile && (
                  <button
                    className="new-configuration__button-mobile btn blue-btn"
                    onClick={showConfigMobileNoURL}
                  >
                    {addScreens}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isDesktop && isConfigDesktopNoURLActive && (
        <ConfigDesktopNoURL screensSessionId={screensSessionId} />
      )}
      {isMobile && isConfigMobileNoURLActive && (
        <ConfigMobileNoURL screensSessionId={screensSessionId} />
      )}
      {isComparisonPopupActive && (
        <div className="main__popup popup-overlay">
          <div className="main__popup-content popup" ref={popupRef}>
            <h2 className="main__popup-heading">Add more screenshots!</h2>
            <p className="main__popup-text">Comparison mode needs at least two screenshots.</p>
            <button className="main__popup-close-btn" onClick={handleClose}>
              <Icons.cross />
            </button>
            <button className="main__popup-ok-btn blue-btn" onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default PageScreenshots;

// send all necessary info to Google Tag Manager
function sendDataAboutScreensToGTM(screenConfig) {
  const { os, browser, resolution } = screenConfig;

  // send info about number of each OS to Google Tag Manager
  const platformsParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'platforms (ext)',
    eventLabel: os,
    eventValue: 1,
  };

  dataLayerPush(platformsParams);

  // send info about number of each browser to Google Tag Manager
  const browsersParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'browsers (ext)',
    eventLabel: browser,
    eventValue: 1,
  };

  dataLayerPush(browsersParams);

  // send info about resolutions to Google Tag Manager
  const screenParams = {
    event: 'customEvent',
    eventCategory: 'info',
    eventAction: 'screen widths (ext)',
    eventLabel: resolution,
    eventValue: 1,
  };

  dataLayerPush(screenParams);
}
