// core
import React, { useState, useEffect, useRef, ReactElement, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
// redux actions
import { uiActions } from '../../../../store/domains/ui/actions';
import { loginProfilesActions } from '../../../../store/domains/loginProfiles/actions';
// instruments
import { disableScroll } from '../../../../helpers';
// images
import Icons from '../../../../images';
// styles
import './loginProfile.scss';

function LoginProfile(props): ReactElement {
  // hooks
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px

  // compute CSS classes
  const loginProfileClasses = cx('configs-authentication__login-profile-list-item', {
    selected: props.selected,
  });

  function editBtnClickHandler(event) {
    event.stopPropagation();

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        const operationWithProfileData = { operation: 'edit', profileID: props.id };

        dispatch(loginProfilesActions.markProfileForEditing(operationWithProfileData));

        if (isDesktop) dispatch(uiActions.toggleLoginProfileCreatorPopup(true));
        else {
          dispatch(uiActions.toggleLoginProfileCreatorPopupMobile(true));
          disableScroll(true);
        }
      });
    });
  }

  function deleteBtnClickHandler(event) {
    event.stopPropagation();

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        dispatch(loginProfilesActions.setProfileForDeletion(props.id));
        dispatch(uiActions.toggleLoginProfileDialog(true));
      });
    });
  }

  return (
    <li
      className={loginProfileClasses}
      onClick={() => dispatch(loginProfilesActions.selectProfile(props.id))}
      tabIndex={0}
      role="button"
      aria-pressed="false"
    >
      {props.title}
      <button
        type="button"
        className="configs-authentication__login-profile-list-item-edit-btn"
        onClick={editBtnClickHandler}
        title="Edit"
      >
        <Icons.pencilHollow />
      </button>
      <button
        type="button"
        className="configs-authentication__login-profile-list-item-delete-btn"
        onClick={deleteBtnClickHandler}
        title="Delete"
      >
        <Icons.bin />
      </button>
    </li>
  );
}

export default React.memo(LoginProfile);
