// core
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
// components
import CheckboxResolution from '../checkboxResolution';
import { Plus } from './components/images';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { resolutionsActions } from '../../store/domains/resolutions/actions';
// instruments
import useTranslations from '../../hooks/useTranslations';
//default settings
import { resolutions as availableResolutions } from '../../settings.json';
// styles
import './resolutions.scss';
// typescript types
import { RootState } from '../../store/rootReducer';

function Resolutions() {
  // hooks
  const dispatch = useDispatch();
  const isWidthSelected = useSelector((state: RootState) => state.ui.isWidthSelected);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const checkboxesWithURL = useSelector((state: RootState) => state.configsCheckboxesWithURL); // all checkboxes which are used to create new screens (not to add new screens)
  const [btnState, setBtnState] = useState(true);
  const [inputState, setInputState] = useState(false);
  const [isRangeValid, setIsRangeValid] = useState(true);
  const [widthExists, setWidthExists] = useState(false);
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [widthMouseEnterState, setWidthMouseEnterState] = useState(false);
  const inputRef = useRef(null);
  const inputWrapperRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 768 }); // if screen width is less than or equal to 768px, render comnponent ConfigMobileNoURL
  const {
    digitsRange,
    thisWidthAlreadyExists,
    pleaseChooseAtLeastOneWidth,
    screenWidths,
    addCustomWidth,
  } = useTranslations();

  // compute CSS classes
  const btnClasses = cx('resolutions__btn', { active: btnState });
  const inputClasses = cx('resolutions__input', {
    invalid: widthExists || !isRangeValid,
  });
  const inputWrapperClasses = cx('resolutions__input-wrapper', {
    active: inputState,
  });
  const widthValidationClasses = cx('validation-message', {
    active: !isWidthSelected,
  });
  const widthExistsValidationClasses = cx('validation-message', {
    active: widthExists,
  });
  const rangeValidationClasses = cx('validation-message', {
    active: !isRangeValid,
  });

  const {
    areScreensUnlimited,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
    license_session_resolutions_count: allowedNumberOfCheckedCheckboxes,
    areCheckedResolutionsUnlimitted,
  } = userLimitsAndStats;

  const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;

  useEffect(() => {
    if (inputState) inputRef.current.focus();
  }, [inputState]);

  useEffect(() => {
    function hide(event: MouseEvent): void {
      if (inputState && !inputWrapperRef.current.contains(event.target)) {
        hideInput();
      }
    }

    function hideOnEsc(event: KeyboardEvent): void {
      if (inputState && event.keyCode === 27) {
        hideInput();
      }
    }

    document.addEventListener('click', hide);
    document.addEventListener('keydown', hideOnEsc);

    return () => {
      document.removeEventListener('click', hide);
      document.removeEventListener('keydown', hideOnEsc);
    };
  }, [inputState]);

  useEffect(() => {
    const areAllChecked = resolutions.every((resolution) => resolution.checked);

    setAreAllChecked(widthMouseEnterState && areAllChecked);
  }, [widthMouseEnterState]);

  function hideInput(): void {
    setBtnState(true);
    setInputState(false);
  }

  function handleKeyPress(event): void {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();

      const inputVal = +inputRef.current.value;

      if (inputVal < 500 || inputVal > 1920) {
        setIsRangeValid(false);
        return;
      } else if (!isRangeValid) {
        setIsRangeValid(true);
      }

      const doesWidthExist = resolutions.find(({ value }) => value === inputVal);

      if (doesWidthExist) {
        setWidthExists(true);
      } else {
        if (widthExists) setWidthExists(false);
        setInputState(false);
        dispatch(resolutionsActions.addResolution(inputVal));
        dispatch(resolutionsActions.checkResolution(inputVal));
      }
    }
  }

  function shouldButtonDisplay(): boolean {
    let flag = true;

    resolutions.forEach(({ value }) => {
      if (!availableResolutions.find((availableResolution) => value === availableResolution)) {
        flag = false;
      }
    });

    return flag;
  }

  function handleSelectAllBtnClick() {
    if (!areCheckedResolutionsUnlimitted) {
      dispatch(uiActions.toggleDemoreminder('limitOfResolutions'));
      return;
    }

    if (!areScreensUnlimited) {
      const numberOfAllowedScreens = screensLimit - screensCount;
      const numberOfExpectedScreens =
        checkboxesWithURL.filter((item) => item.checked).length * resolutions.length;

      if (numberOfAllowedScreens < numberOfExpectedScreens) {
        dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
        return;
      }
    }

    dispatch(resolutionsActions.checkOrUncheckAllResolutions());
  }

  function handleAddBtnClick(event) {
    event.preventDefault();

    if (
      !areCheckedResolutionsUnlimitted &&
      numberOfCheckedResolutions === allowedNumberOfCheckedCheckboxes
    ) {
      dispatch(uiActions.toggleDemoreminder('limitOfResolutions'));
      return;
    }

    if (!areScreensUnlimited) {
      const numberOfCheckedCheckboxes = checkboxesWithURL.filter((item) => item.checked).length;
      const numberOfExpectedCheckedResolutions = numberOfCheckedResolutions + 1;
      const numberOfExpectedScreens =
        numberOfCheckedCheckboxes * numberOfExpectedCheckedResolutions;
      const numberOfAllowedScreens = screensLimit - screensCount;

      if (numberOfExpectedScreens > numberOfAllowedScreens) {
        dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
        return;
      }
    }

    setBtnState(false);
    setInputState(true);
  }

  // compute "Select all" button label depending on number of selected resolutions
  const label = resolutions.length === numberOfCheckedResolutions ? 'Unselect all' : 'Select all';

  return (
    <div className="resolutions">
      <div className="resolutions__wrapper">
        <p
          className="resolutions__label"
          onMouseEnter={() => setWidthMouseEnterState(true)}
          onMouseLeave={() => setWidthMouseEnterState(false)}
          onClick={handleSelectAllBtnClick}
        >
          {screenWidths}:
          <span className="resolutions__label-number">{` (${numberOfCheckedResolutions})`}</span>
        </p>
        {isMobile && (
          <button type="button" className="btn-select" onClick={handleSelectAllBtnClick}>
            {label}
          </button>
        )}
      </div>
      <div className="resolutions__content">
        {resolutions.map(({ value, checked }) => (
          <CheckboxResolution
            key={value}
            resolution={value}
            checked={checked}
            widthMouseEnterState={widthMouseEnterState}
            enableBtn={setBtnState}
            areAllChecked={areAllChecked}
          />
        ))}
        {shouldButtonDisplay() && (
          <button
            className={btnClasses}
            type="button"
            title={addCustomWidth}
            onClick={handleAddBtnClick}
          >
            <Plus />
          </button>
        )}
        <div className={inputWrapperClasses} ref={inputWrapperRef}>
          <input
            className={inputClasses}
            type="number"
            ref={inputRef}
            onKeyPress={handleKeyPress}
          />
          <span className="resolutions__input-label">px</span>
        </div>
        <p className={widthValidationClasses}>{pleaseChooseAtLeastOneWidth}</p>
        <p className={widthExistsValidationClasses}>{thisWidthAlreadyExists}</p>
        <p className={rangeValidationClasses}>{digitsRange}</p>
      </div>
    </div>
  );
}

export default React.memo(Resolutions);
