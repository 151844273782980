import React from 'react';

export function Computer() {
  return (
    <svg
      className="resolution__icon"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="resolution__icon-path"
        d="M2 13H16C16.6 13 17 12.6 17 12V1C17 0.4 16.6 0 16 0H2C1.4 0 1 0.4 1 1V12C1 12.6 1.4 13 2 13ZM3 2.5C3 2.2 3.2 2 3.5 2H14.5C14.8 2 15 2.2 15 2.5V10.5C15 10.8 14.8 11 14.5 11H3.5C3.2 11 3 10.8 3 10.5V2.5Z"
        fill="#3DC3E2"
      />
      <path
        className="resolution__icon-path"
        d="M13 14.5C13 14.9 12.8 15.1 12.5 15.1H5.5C5.2 15.1 5 14.9 5 14.6V14.5C5 14.2 4.8 14 4.5 14H0V16C0 16.6 0.4 17 1 17H17C17.6 17 18 16.6 18 16V14H13.5C13.2 14 13 14.2 13 14.5Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}

export function ComputerHD() {
  return (
    <svg
      className="resolution__icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="resolution__icon-path"
        d="M17 0H1C0.4 0 0 0.4 0 1V14C0 14.6 0.4 15 1 15H6V16H4C2.9 16 2 16.9 2 18H16C16 16.9 15.1 16 14 16H12V15H17C17.6 15 18 14.6 18 14V1C18 0.4 17.6 0 17 0ZM16 12.5C16 12.8 15.8 13 15.5 13H2.5C2.2 13 2 12.8 2 12.5V2.5C2 2.2 2.2 2 2.5 2H15.5C15.8 2 16 2.2 16 2.5V12.5Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}

export function Tablet() {
  return (
    <svg
      className="resolution__icon"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="resolution__icon-path"
        d="M13 0H1C0.4 0 0 0.4 0 1V17C0 17.6 0.4 18 1 18H13C13.6 18 14 17.6 14 17V1C14 0.4 13.6 0 13 0ZM7 17C6.4 17 6 16.6 6 16C6 15.4 6.4 15 7 15C7.6 15 8 15.4 8 16C8 16.6 7.6 17 7 17ZM12 13.5C12 13.8 11.8 14 11.5 14H2.5C2.2 14 2 13.8 2 13.5V2.5C2 2.2 2.2 2 2.5 2H11.5C11.8 2 12 2.2 12 2.5V13.5Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}

export function Mobile() {
  return (
    <svg
      className="resolution__icon"
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="resolution__icon-path"
        d="M9 0.5H1C0.4 0.5 0 0.9 0 1.5V15.5C0 16.1 0.4 16.5 1 16.5H9C9.6 16.5 10 16.1 10 15.5V1.5C10 0.9 9.6 0.5 9 0.5ZM5 15.5C4.4 15.5 4 15.1 4 14.5C4 13.9 4.4 13.5 5 13.5C5.6 13.5 6 13.9 6 14.5C6 15.1 5.6 15.5 5 15.5ZM8 12C8 12.3 7.8 12.5 7.5 12.5H2.5C2.2 12.5 2 12.3 2 12V3C2 2.7 2.2 2.5 2.5 2.5H7.5C7.8 2.5 8 2.7 8 3V12Z"
        fill="#3DC3E2"
      />
    </svg>
  );
}

export function Close() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M7.46218 5.9L11.6975 1.7C12.1008 1.3 12.1008 0.7 11.6975 0.3C11.2941 -0.1 10.6891 -0.1 10.2857 0.3L5.94958 4.5L1.71429 0.3C1.31092 -0.0999999 0.705882 -0.0999999 0.302521 0.3C-0.10084 0.7 -0.10084 1.3 0.302521 1.7L4.53782 5.9L0.302521 10.1C-0.10084 10.5 -0.10084 11.1 0.302521 11.5C0.705882 11.9 1.31092 11.9 1.71429 11.5L5.94958 7.3L10.1849 11.5C10.5882 11.9 11.1933 11.9 11.5966 11.5C12 11.1 12 10.5 11.5966 10.1L7.46218 5.9Z"
        fill="#9DA2AA"
      />
    </svg>
  );
}
