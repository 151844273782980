// core
import React, { useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// instruments
import { filterCheckboxes, checkIfAllConfigsCanBeChecked } from '../../helpers';
// images
import osIcons from '../../images/osBigIcons';
import browserIcons from '../../images/browserBigIcons';
// typescript types
import { CheckboxConfig } from '../../store/domains/commonTypes';
import { RootState } from '../../store/rootReducer';
type Props = {
  mayBeAlreadyChecked: boolean;
  checkboxes: CheckboxConfig[];
  checkBrowserGroupConfigs: Function;
  checkAllConfigs: Function;
  checkOSGroupConfigs: Function;
  buttonLabel: string;
  isBtnDisabled?: boolean;
  children?: ReactNode;
};

function TableConfigsDesktop(props: Props) {
  // hooks
  const dispatch = useDispatch();
  const [massSelectHover, setMassSelectHover] = useState<string | boolean>(false);
  const areCredentialsConfirmed = useSelector(
    (state: RootState) => state.ui.areCredentialsForBasicAuthConfirmed
  );
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);

  // compute CSS classes
  const tableCellHeadingClasses = cx('configurations__table-cell', {
    disabled: areCredentialsConfirmed || isSmartScrollOn,
  });

  // props destructuring
  const {
    checkboxes,
    mayBeAlreadyChecked,
    checkBrowserGroupConfigs,
    checkOSGroupConfigs,
    checkAllConfigs,
    buttonLabel,
    isBtnDisabled,
    children,
  } = props;

  const {
    areScreensUnlimited,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
    areCheckedConfigsUnlimitted,
  } = userLimitsAndStats;

  const areAllItemsInThisGroupChecked = !checkboxes.some(
    (item) =>
      (item.os === massSelectHover ||
        item.browser === massSelectHover ||
        massSelectHover === 'all') &&
      item.checked === false
  );

  const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;

  function handleAllCheck() {
    if (!areCheckedConfigsUnlimitted) {
      dispatch(uiActions.toggleDemoreminder('limitOfTakenScreenshots'));
      return;
    }

    const areAllCheckboxesChecked = checkboxes.every((item) => item.checked);

    if (!areScreensUnlimited && !areAllCheckboxesChecked) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;
      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: mayBeAlreadyChecked,
      };
      const canAllConfigsBeChecked = checkIfAllConfigsCanBeChecked(params);

      if (!canAllConfigsBeChecked) return;
    }

    dispatch(checkAllConfigs());
  }

  function handleMassOSCheck(os: string) {
    if (!areCheckedConfigsUnlimitted) {
      dispatch(uiActions.toggleDemoreminder('limitOfTakenScreenshots'));
      return;
    }

    if (!areScreensUnlimited) {
      const allCheckboxesOfCurrentOs = checkboxes.filter((item) => item.os === os);
      const areAllCheckboxesOfCurrentOsChecked = allCheckboxesOfCurrentOs.every(
        (item) => item.checked
      );

      if (!areAllCheckboxesOfCurrentOsChecked) {
        const numberOfAlreadyCheckedCheckboxes = checkboxes.filter((item) => item.checked).length;
        const numberOfCurrentOsUncheckedCheckboxes = allCheckboxesOfCurrentOs.filter(
          (item) => !item.checked
        ).length;
        const numberOfAllowedScreens = screensLimit - screensCount;
        let numberOfExpectedScreens;

        if (mayBeAlreadyChecked) {
          numberOfExpectedScreens =
            (numberOfCurrentOsUncheckedCheckboxes + numberOfAlreadyCheckedCheckboxes) *
            numberOfCheckedResolutions;
        } else {
          numberOfExpectedScreens =
            numberOfCurrentOsUncheckedCheckboxes + numberOfAlreadyCheckedCheckboxes;
        }

        if (numberOfAllowedScreens < numberOfExpectedScreens) {
          dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
          return;
        }
      }
    }

    dispatch(checkOSGroupConfigs(os));
  }

  function handleMassBrowserCheck(browser: string) {
    if (!areCheckedConfigsUnlimitted) {
      dispatch(uiActions.toggleDemoreminder('limitOfTakenScreenshots'));
      return;
    }

    if (!areScreensUnlimited) {
      const allCheckboxesOfCurrentBrowser = checkboxes.filter((item) => item.browser === browser);
      const areAllCheckboxesOfCurrentBrowserChecked = allCheckboxesOfCurrentBrowser.every(
        (item) => item.checked
      );

      if (!areAllCheckboxesOfCurrentBrowserChecked) {
        const numberOfAlreadyCheckedCheckboxes = checkboxes.filter((item) => item.checked).length;
        const numberOfCurrentBrowserUncheckedCheckboxes = allCheckboxesOfCurrentBrowser.filter(
          (item) => !item.checked
        ).length;
        const numberOfAllowedScreens = screensLimit - screensCount;
        let numberOfExpectedScreens;

        if (mayBeAlreadyChecked) {
          numberOfExpectedScreens =
            (numberOfCurrentBrowserUncheckedCheckboxes + numberOfAlreadyCheckedCheckboxes) *
            numberOfCheckedResolutions;
        } else {
          numberOfExpectedScreens =
            numberOfCurrentBrowserUncheckedCheckboxes + numberOfAlreadyCheckedCheckboxes;
        }

        if (numberOfAllowedScreens < numberOfExpectedScreens) {
          dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
          return;
        }
      }
    }

    dispatch(checkBrowserGroupConfigs(browser));
  }

  const windows10ChromeCheckboxesJSX = filterCheckboxes(
    checkboxes,
    '10WINDOWS',
    'chrome',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const windows10FirefoxCheckboxesJSX = filterCheckboxes(
    checkboxes,
    '10WINDOWS',
    'firefox',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const osxChromeCheckboxesJSX = filterCheckboxes(
    checkboxes,
    'OSX',
    'chrome',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const linuxChromeCheckboxesJSX = filterCheckboxes(
    checkboxes,
    'LINUX',
    'chrome',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const osxFirefoxCheckboxesJSX = filterCheckboxes(
    checkboxes,
    'OSX',
    'firefox',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const linuxFirefoxCheckboxesJSX = filterCheckboxes(
    checkboxes,
    'LINUX',
    'firefox',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  const osxSafariCheckboxesJSX = filterCheckboxes(
    checkboxes,
    'OSX',
    'safari',
    mayBeAlreadyChecked,
    massSelectHover,
    areAllItemsInThisGroupChecked
  );

  return (
    <table className="configurations__table">
      <tbody>
        <tr className="configurations__table-row">
          <td
            className="configurations__table-cell"
            onClick={handleAllCheck}
            onMouseEnter={() => setMassSelectHover('all')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            OS/Browser:
          </td>
          <td
            className="configurations__table-cell"
            onClick={() => handleMassBrowserCheck('chrome')}
            onMouseEnter={() => setMassSelectHover('chrome')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <img
              src={browserIcons.chrome}
              alt="Chrome"
              title="Chrome"
              className="configurations__table-img"
            />
            Chrome
          </td>
          <td
            className="configurations__table-cell"
            onClick={() => handleMassBrowserCheck('firefox')}
            onMouseEnter={() => setMassSelectHover('firefox')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <img
              src={browserIcons.firefox}
              alt="Mozilla Firefox"
              title="Mozilla Firefox"
              className="configurations__table-img"
            />
            Firefox
          </td>
          <td
            className={tableCellHeadingClasses}
            onClick={() => handleMassBrowserCheck('safari')}
            onMouseEnter={() => setMassSelectHover('safari')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <img
              src={areCredentialsConfirmed ? browserIcons.safariDisabled : browserIcons.safari}
              alt="Safari"
              title="Safari"
              className="configurations__table-img"
            />
            Safari
          </td>
        </tr>
        <tr className="configurations__table-row">
          <td
            className="configurations__table-cell"
            onClick={() => handleMassOSCheck('10WINDOWS')}
            onMouseEnter={() => setMassSelectHover('10WINDOWS')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <div className="configurations__table-cell-wrapper">
              <img
                src={osIcons.windows10Blue}
                alt="Windows 10"
                title="Windows 10"
                className="configurations__table-img"
              />
              Windows 10
            </div>
          </td>
          <td className="configurations__table-cell">{windows10ChromeCheckboxesJSX}</td>
          <td className="configurations__table-cell">{windows10FirefoxCheckboxesJSX}</td>
          <td className="configurations__table-cell"></td>
        </tr>
        <tr className="configurations__table-row">
          <td
            className="configurations__table-cell"
            onClick={() => handleMassOSCheck('OSX')}
            onMouseEnter={() => setMassSelectHover('OSX')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <div className="configurations__table-cell-wrapper">
              <img
                src={osIcons.mac}
                alt="macOS"
                title="macOS"
                className="configurations__table-img"
              />
              macOS
            </div>
          </td>
          <td className="configurations__table-cell">{osxChromeCheckboxesJSX}</td>
          <td className="configurations__table-cell">{osxFirefoxCheckboxesJSX}</td>
          <td className="configurations__table-cell">{osxSafariCheckboxesJSX}</td>
        </tr>
        <tr className="configurations__table-row">
          <td
            className="configurations__table-cell"
            onClick={() => handleMassOSCheck('LINUX')}
            onMouseEnter={() => setMassSelectHover('LINUX')}
            onMouseLeave={() => setMassSelectHover(false)}
          >
            <div className="configurations__table-cell-wrapper">
              <img
                src={osIcons.linux}
                alt="Linux"
                title="Linux"
                className="configurations__table-img"
              />
              Linux
            </div>
          </td>
          <td className="configurations__table-cell">{linuxChromeCheckboxesJSX}</td>
          <td className="configurations__table-cell">{linuxFirefoxCheckboxesJSX}</td>
          <td className="configurations__table-cell"></td>
        </tr>
        <tr className="configurations__table-row">
          <td className="configurations__table-cell"></td>
          <td className="configurations__btn">
            {children ? children : null}
            <button
              className="btn blue-btn configurations__btn-button"
              type="submit"
              disabled={isBtnDisabled}
            >
              {buttonLabel}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

TableConfigsDesktop.defaultProps = {
  isBtnDisabled: false,
};

export default React.memo(TableConfigsDesktop);
