import React from 'react';

export function QuestionMark() {
  return (
    <svg
      className="configs-authentication__smartscroll-question-icon"
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="configs-authentication__smartscroll-question-icon-path"
        d="M2.81375 5.84504C2.74475 5.84504 2.68954 5.82434 2.64814 5.78294C2.60674 5.74154 2.58604 5.68634 2.58604 5.61734V5.35581C2.59984 4.99011 2.69299 4.67271 2.8655 4.40361C3.0449 4.13451 3.29675 3.83435 3.62105 3.50315C3.86945 3.24785 4.0523 3.0374 4.1696 2.8718C4.2938 2.7062 4.36625 2.5268 4.38695 2.3336C4.39385 2.30599 4.3973 2.26114 4.3973 2.19904C4.3973 1.88164 4.26275 1.62979 3.99365 1.44349C3.72455 1.25719 3.4106 1.16404 3.0518 1.16404C2.66539 1.16404 2.34109 1.26754 2.07889 1.47454C1.82359 1.67464 1.65799 1.99204 1.58209 2.42675C1.55449 2.57855 1.46479 2.65445 1.31299 2.65445H0.857584C0.795484 2.65445 0.740283 2.63375 0.691983 2.59235C0.650583 2.55095 0.629883 2.49575 0.629883 2.42675C0.643683 2.04724 0.750633 1.69879 0.950735 1.38139C1.15774 1.05709 1.44409 0.801786 1.80979 0.615485C2.18239 0.422284 2.60674 0.325684 3.08285 0.325684C3.58655 0.325684 4.0109 0.411934 4.3559 0.584435C4.70091 0.756936 4.95621 0.984637 5.12181 1.26754C5.28741 1.54354 5.37021 1.83679 5.37021 2.14729C5.37021 2.4992 5.29086 2.8028 5.13216 3.0581C4.97346 3.3134 4.73541 3.6101 4.418 3.9482C4.1558 4.23111 3.95915 4.47261 3.82805 4.67271C3.69695 4.86591 3.62105 5.08326 3.60035 5.32476C3.58655 5.51796 3.57965 5.50004 3.57965 5.59664C3.54515 5.76224 3.4589 5.84504 3.3209 5.84504H2.81375ZM2.7206 7.67423C2.65159 7.67423 2.59294 7.65353 2.54464 7.61213C2.50324 7.56383 2.48254 7.50517 2.48254 7.43617V6.83587C2.48254 6.76687 2.50324 6.70822 2.54464 6.65992C2.59294 6.61162 2.65159 6.58747 2.7206 6.58747H3.3623C3.4313 6.58747 3.48995 6.61162 3.53825 6.65992C3.58655 6.70822 3.6107 6.76687 3.6107 6.83587V7.43617C3.6107 7.50517 3.58655 7.56383 3.53825 7.61213C3.48995 7.65353 3.4313 7.67423 3.3623 7.67423H2.7206Z"
        fill="#C9CDD3"
      />
    </svg>
  );
}

export function QuestionMarkMobile() {
  return (
    <svg
      className="configs-authentication__smartscroll-question-icon"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="configs-authentication__smartscroll-question-icon-path"
        d="M5.7 1.2001C5.5 0.900098 5.2 0.600098 4.8 0.400098C4.4 0.200098 3.8 0.100098 3.2 0.100098C2.6 0.100098 2 0.200098 1.5 0.500098C1 0.800098 0.6 1.0001 0.4 1.4001C0.1 1.8001 0 2.2001 0 2.6001C0 2.7001 0 2.7001 0 2.7001C0 3.1001 0.4 3.5001 0.8 3.5001C1.2 3.5001 1.5 3.2001 1.6 2.8001C1.6 2.7001 1.6 2.6001 1.6 2.6001C1.8 1.8001 2.2 1.4001 3 1.4001C3.3 1.4001 3.6 1.5001 3.9 1.7001C4.2 2.0001 4.3 2.2001 4.3 2.6001C4.3 2.8001 4.2 3.0001 4.1 3.2001C3.9 3.3001 3.7 3.5001 3.4 3.8001C3 4.2001 2.8 4.5001 2.6 4.8001C2.4 5.0001 2.3 5.4001 2.2 5.7001C2.2 5.8001 2.2 5.9001 2.2 5.9001C2.2 6.4001 2.6 6.8001 3.1 6.8001C3.6 6.8001 4 6.5001 4 6.0001C4 6.0001 4 6.0001 4 5.9001C4 5.9001 4 5.9001 4.1 5.6001C4.1 5.4001 4.2 5.2001 4.4 5.0001C4.5 4.8001 4.7 4.6001 5 4.3001C5.4 3.9001 5.6 3.6001 5.8 3.3001C5.9 3.0001 6 2.7001 6 2.3001C6 1.9001 5.9 1.6001 5.7 1.2001Z"
        fill="white"
      />
      <path
        className="configs-authentication__smartscroll-question-icon-path"
        d="M3.0999 7.6001C2.4999 7.6001 1.8999 8.1001 1.8999 8.8001C1.8999 9.4001 2.3999 10.0001 3.0999 10.0001C3.6999 10.0001 4.2999 9.5001 4.2999 8.8001C4.2999 8.1001 3.7999 7.6001 3.0999 7.6001Z"
        fill="white"
      />
    </svg>
  );
}
