// core
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { resolutionsActions } from '../../store/domains/resolutions/actions';
// styles
import './checkboxResolution.scss';
// images
import { Close, Computer, ComputerHD, Tablet, Mobile } from './components/images';
// typescript types
import { RootState } from '../../store/rootReducer';
type Props = {
  checked: boolean;
  resolution: number;
  areAllChecked: boolean;
  widthMouseEnterState: boolean;
  enableBtn: Function;
};

function CheckboxResolution(props: Props) {
  // hooks
  const dispatch = useDispatch();
  const checkboxesWithURL = useSelector((state: RootState) => state.configsCheckboxesWithURL); // all checkboxes which are used to create new screens (not to add new screens)
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);

  const {
    areScreensUnlimited,
    license_subscription_screens_count: screensLimit,
    count_screens_for_period: screensCount,
    license_session_resolutions_count: allowedNumberOfCheckedResolutions,
    areCheckedResolutionsUnlimitted,
  } = userLimitsAndStats;

  // destructuring props
  const { checked, resolution, areAllChecked, widthMouseEnterState, enableBtn } = props;

  // compute CSS classes
  const classes = cx(
    'resolution',
    { checked },
    { 'screen-width-hover': areAllChecked || (!checked && widthMouseEnterState) }
  );

  const numberOfCheckedResolutions = resolutions.filter((item) => item.checked).length;

  // define icon for each resolution
  const Icon = defineIcon(resolution);

  function handleChange() {
    if (
      !areCheckedResolutionsUnlimitted &&
      !checked &&
      numberOfCheckedResolutions === allowedNumberOfCheckedResolutions
    ) {
      dispatch(uiActions.toggleDemoreminder('limitOfResolutions'));
      return;
    }

    if (!areScreensUnlimited && !checked) {
      const numberOfCheckedCheckboxes = checkboxesWithURL.filter((item) => item.checked).length;
      const numberOfExpectedCheckedResolutions = numberOfCheckedResolutions + 1;
      const numberOfExpectedScreens =
        numberOfCheckedCheckboxes * numberOfExpectedCheckedResolutions;
      const numberOfAllowedScreens = screensLimit - screensCount;

      if (numberOfExpectedScreens > numberOfAllowedScreens) {
        dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
        return;
      }
    }

    if (checked) {
      dispatch(resolutionsActions.uncheckResolution(resolution));
    } else {
      dispatch(resolutionsActions.checkResolution(resolution));
    }
  }

  function handleClick(event) {
    event.preventDefault();

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        dispatch(resolutionsActions.deleteResolution(resolution));
      });
    });

    enableBtn(true);
  }

  return (
    <label className={classes}>
      <input
        className="resolution__checkbox"
        checked={checked}
        onChange={handleChange}
        name="resolution"
        type="checkbox"
        value={resolution}
      />
      {Icon && <Icon />}
      {`${resolution} px`}
      {!Icon && (
        <button type="button" className="resolution__btn" onClick={handleClick}>
          <Close />
        </button>
      )}
    </label>
  );
}

export default React.memo(CheckboxResolution);

function defineIcon(resolution: number): FC {
  switch (resolution) {
    case 500:
      return Mobile;

    case 768:
      return Tablet;

    case 1024:
      return Computer;

    case 1920:
      return ComputerHD;

    default:
      return null;
  }
}
