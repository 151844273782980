import React from 'react';

export function Plus() {
  return (
    <svg
      className="resolutions__btn-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="resolutions__btn-icon-path"
        d="M9.06043 8.99275L14.0101 8.99272C14.5757 8.99272 15 8.56846 15 8.00277C15 7.43709 14.5757 7.01282 14.01 7.01282L8.99997 7.00198L8.98992 1.99236C8.98992 1.42668 8.56566 1.00242 7.99997 1.00242C7.43429 1.00242 7.01002 1.42668 7.01002 1.99236L7.00983 7.01285L1.98992 7.01285C1.42424 7.01285 0.999974 7.43711 0.999974 8.0028C0.999974 8.56848 1.42424 8.99275 1.98992 8.99275L7.00983 8.99275V14.0125C7.00983 14.5782 7.43409 15.0024 7.99977 15.0024C8.56546 15.0024 8.98972 14.5782 8.98972 14.0125L9.06043 8.99275Z"
        fill="#9DA2AA"
      />
    </svg>
  );
}
