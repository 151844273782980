// core
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
// instruments
import {
  defineOsBigIcon,
  defineBrowserBigIcon,
  filterCheckboxes,
  /*convertIntExpToIE,*/
  defineBrowserIconDisabled,
  defineBrowserIconNotActive,
  defineOsTitle,
  defineBrowserTitle,
  checkIfAllConfigsCanBeChecked,
} from '../../helpers';
import useTranslations from '../../hooks/useTranslations';
import { DraggableSlider } from '../../shared';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
import { configsCheckboxesNoURLActions } from '../../store/domains/configsCheckboxesNoURL/actions';
// styles
import './tabs.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
import { CheckboxConfig } from '../../store/domains/commonTypes';
type Props = {
  type: string;
  numberOfCheckedConfigs: number;
  checkboxes: CheckboxConfig[];
};

const tabs = [
  { browser: 'chrome', os: ['10WINDOWS', 'OSX', 'LINUX'] },
  { browser: 'firefox', os: ['10WINDOWS', 'OSX', 'LINUX'] },
  { browser: 'safari', os: ['OSX'] },
  // { browser: 'internet explorer', os: ['OSX', 'LINUX'] },
  // { browser: 'opera', os: ['10WINDOWS', 'OSX', 'LINUX'] },
];

function Tabs(props: Props) {
  //hooks
  const dispatch = useDispatch();
  const areCredentialsConfirmed = useSelector(
    (state: RootState) => state.ui.areCredentialsForBasicAuthConfirmed
  );
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const [activeTab, setActiveTab] = useState(tabs[0].browser);
  const tabListRef = useRef<{ current: HTMLElement }>(null);
  const tabContentRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);
  const tabRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);
  const { noAvailableVersions } = useTranslations();

  useEffect(() => {
    const width = tabRefs.current.reduce((accum, val) => accum + val.current.offsetWidth, 0) + 40;

    new DraggableSlider(tabListRef.current, width);
  }, []);

  // автоматически изменять размер прокрутки при увеличении табов
  /*function tabsClick() {
    const width =
      tabRefs.current.reduce(
        (accum, val) => accum + val.current.offsetWidth,
        0
      ) - window.innerWidth;

    Draggable.get(tabListRef.current).applyBounds({
      minX: width * -1,
      maxX: 0,
    });
  }*/

  const areAllChecked = props.numberOfCheckedConfigs === props.checkboxes.length;

  function handleClick() {
    const {
      areScreensUnlimited,
      license_subscription_screens_count: screensLimit,
      count_screens_for_period: screensCount,
    } = userLimitsAndStats;

    if (!areScreensUnlimited && !areAllChecked) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;
      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes: props.checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: props.type === 'withURL',
      };
      const canAllConfigsBeChecked = checkIfAllConfigsCanBeChecked(params);

      if (!canAllConfigsBeChecked) return;
    }

    if (props.type === 'withURL') {
      dispatch(configsCheckboxesWithURLActions.checkAllConfigs());
    } else {
      dispatch(configsCheckboxesNoURLActions.checkAllConfigs());
    }
  }

  function onClickTabItem(tab) {
    const index = tabs.findIndex((item) => item.browser === tab);

    tabContentRefs.current.forEach((element) => element.current.classList.remove('active'));
    tabContentRefs.current[index].current.classList.add('active');

    setActiveTab(tab);
  }

  return (
    <>
      <div className="selection-status">
        <p className="selection-status__label">
          OS/Browser
          <span className="selection-status__number">{` (${props.numberOfCheckedConfigs})`}</span>
        </p>
        <button type="button" className="btn-select" onClick={handleClick}>
          {areAllChecked ? 'Unselect all' : 'Select all'}
        </button>
      </div>
      <div className="tabs">
        <div className="tabs__wrapper">
          <ol className="tabs__list" ref={tabListRef}>
            {tabs.map((child, index) => {
              const browser = child.browser;
              const isTabActive = activeTab === browser;

              const className = cx('tabs__list-item', {
                active: isTabActive,
                disabled: areCredentialsConfirmed,
                /*(areCredentialsConfirmed ||
                    (isSmartScrollOn && browser === 'internet explorer')) &&
                  browser !== 'firefox' &&
                  browser !== 'chrome'*/
              });
              const number = props.checkboxes.filter(
                (item) => item.browser === browser && item.checked
              ).length;
              //const label = defineBrowserTitle(convertIntExpToIE(browser));
              const label = defineBrowserTitle(browser);
              const image =
                /*(areCredentialsConfirmed || (isSmartScrollOn && browser === 'internet explorer')) &&
                browser !== 'firefox' &&
                browser !== 'chrome'*/
                areCredentialsConfirmed
                  ? defineBrowserIconDisabled(browser)
                  : isTabActive
                  ? defineBrowserBigIcon(browser)
                  : defineBrowserIconNotActive(browser);

              return (
                <li
                  key={index}
                  ref={tabRefs.current[index]}
                  className={className}
                  onClick={() => onClickTabItem(browser)}
                >
                  <img src={image} alt={browser} className="tabs__list-item-img" />
                  {label}
                  {number > 0 && <span className="tabs__list-item-number">{`(${number})`}</span>}
                </li>
              );
            })}
          </ol>
        </div>
        {tabs.map((item, index) => (
          <div
            key={index}
            className={cx('tabs__content', { active: index === 0 })}
            ref={tabContentRefs.current[index]}
          >
            {item.os.map((os, index) => (
              <div key={index} className="configurations-mobile__block">
                <div className="configurations-mobile__block-heading">
                  <div className="configurations-mobile__block-heading-img-wrapper">
                    <img
                      src={defineOsBigIcon(os)}
                      alt={os}
                      className="configurations-mobile__block-heading-img"
                    />
                  </div>
                  {defineOsTitle(os)} + {defineBrowserTitle(item.browser)} v.
                </div>
                <div className="configurations-mobile__block-versions">
                  {filterCheckboxes(
                    props.checkboxes,
                    os,
                    item.browser,
                    props.type === 'withURL',
                    false
                  )}
                </div>
                {props.checkboxes.filter(
                  (element) => element.os === os && element.browser === item.browser
                ).length === 0 && (
                  <p className="configurations-mobile__block-text">{noAvailableVersions}</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(Tabs);
