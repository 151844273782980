// core
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
// instruments
import {
  defineOsTitle,
  defineOsTitleMobile,
  defineOsBigIcon,
  defineBrowserBigIcon,
  defineBrowserTitle,
  sendScreensToServer,
  generateHashForVncSession,
} from '../../helpers';
import { useAuth0 } from '../../helpers/auth';
import useTranslations from '../../hooks/useTranslations';
import { api } from '../../api';
//socket
import { _socket as socket } from '../../socket';
//default settings
import { app_metadata_namespace } from '../../settings.json';
// images
import loadingIcon from './images/loading.svg';
import Icons from '../../images';
// styles
import './screenshot.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
type Props = {
  screenId: string;
  os: string;
  browser: string;
  status: 'loading' | 'error' | 'available';
  number: number;
  version: string;
  resolution: number;
  preview: string;
  checked: boolean;
  isSmartScrollOn: boolean;
};

function Screenshot(props: Props) {
  // destructuring props
  const {
    os,
    browser,
    status,
    number,
    version,
    resolution,
    preview,
    checked,
    screenId,
    isSmartScrollOn,
  } = props;

  // hooks
  const dispatch = useDispatch();
  const url = useSelector((state: RootState) => state.ui.url);
  const selectionStatus = useSelector((state: RootState) => state.ui.massSelectionStatus);
  const credentials = useSelector((state: RootState) => state.ui.credentialsForBasicAuth);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const currentScreensSessionId = useSelector((state: RootState) => state.ui.screensSessionId); // id of the current screenshots session - we need it in order to add new screenshots for creation and not update stats of screens created per month
  const screenRef = useRef(null);
  const menuRef = useRef(null);
  const [menu, openMenu] = useState(false);
  const [blur, setBlur] = useState(false);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const isDesktop = useMediaQuery({ minWidth: 769 }); // if screen width is more than 768px
  const { viewscreen, Delete, update, refreshScreenshot, deleteScreenshot } = useTranslations();
  const { user } = useAuth0();

  const userId = user?.sub;

  useEffect(() => {
    function hideMenu(event: MouseEvent) {
      if (menu && !menuRef.current.contains(event.target)) {
        openMenu(false);
      }
    }

    document.addEventListener('click', hideMenu);

    return () => document.removeEventListener('click', hideMenu);
  }, [menu]);

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  // destructuring actions
  const { toggleCheckScreen } = screensActions;

  // compute CSS classes
  const menuClasses = cx('thumbnail__mobile-menu', { active: menu });
  const selectClasses = cx('thumbnail__select', { checked }, { active: selectionStatus });
  const checkboxClasses = cx('thumbnail__select-checkbox', { checked }, { blur });
  const selectOverlayLabelClasses = cx('thumbnail__select-overlay-label', {
    blur,
  });

  // get icons and titles related to screenshot
  const osIcon = defineOsBigIcon(os);
  const browserIcon = defineBrowserBigIcon(browser);
  const osTitle = defineOsTitle(os);
  const browserTitle = defineBrowserTitle(browser);

  // get current screen config
  const thisScreenConfig = { os, browser, version, resolution, screenId };

  function viewScreen(): void {
    dispatch(uiActions.setScreenNumber(number));
    dispatch(uiActions.showModal());

    document.body.style.overflow = 'hidden'; // disable scroll of main application
  }

  function deleteScreen(): void {
    dispatch(uiActions.setScreenConfigForDeletion(thisScreenConfig));
    dispatch(uiActions.showDialog());
  }

  function updateScreen(): void {
    if (menu) openMenu(false);

    const { areScreensUnlimited, isScreensLimitReached } = userLimitsAndStats;

    // check if the user has reached the limit of screenshots per month
    if (isScreensLimitReached && !areScreensUnlimited) {
      dispatch(uiActions.toggleDemoreminder('limitOfScreenshotsPerMonth'));
      return;
    }

    dispatch(screensActions.updateScreen(screenId));

    const { user_auth, user_pass } = credentials;
    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    sendScreensToServer({
      url,
      socket,
      configs: [thisScreenConfig],
      userId,
      noCache: true,
      user_auth,
      user_pass,
      loginProfileId,
      sessionId: currentScreensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn,
    });
  }

  function checkScreen(): void {
    dispatch(toggleCheckScreen({ ...thisScreenConfig, checked: true }));
    dispatch(uiActions.toggleSelectionStatus(true));
  }

  function handleChange(): void {
    dispatch(toggleCheckScreen({ ...thisScreenConfig, checked: !checked }));
  }

  let touchStartTimeStamp = 0;
  let touchEndTimeStamp = 0;

  function handleTouchStart(event): void {
    touchStartTimeStamp = event.timeStamp;
  }

  function handleTouchEnd(event): void {
    touchEndTimeStamp = event.timeStamp;

    if (touchEndTimeStamp - touchStartTimeStamp > 1000) {
      dispatch(toggleCheckScreen({ ...thisScreenConfig, checked: true }));
      dispatch(uiActions.toggleSelectionStatus(true));
    }
  }

  // async function handleRunLiveTestClick() {
  //   const { isLiveSessionsLimitReached, areLiveSessionsUnlimited } = userLimitsAndStats;

  //   if (isLiveSessionsLimitReached && !areLiveSessionsUnlimited) {
  //     dispatch(uiActions.toggleDemoreminder('limitOfLivetesting'));
  //     return;
  //   }

  //   let isParallelSessionsLimitReached: boolean;
  //   const {
  //     license_product: licenseProduct,
  //     billing_operator: billingOperator,
  //     last_order_reference: lastOrderReference,
  //   } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

  //   try {
  //     const request = await api.checkIfParallelSessionsLimitReached(userId, licenseProduct);
  //     isParallelSessionsLimitReached = !request?.data; // if data is equal to true, that means that limit of parallel sessions was not reached
  //     console.log('request', request);
  //   } catch (error) {
  //     console.log('error of getting parallel sessions limit', error);
  //   }

  //   if (isParallelSessionsLimitReached) {
  //     dispatch(uiActions.toggleDemoreminder('limitOfParallelTesting'));

  //     const params = { userId, licenseProduct, billingOperator, lastOrderReference };

  //     socket.emit('getUserData', params);

  //     return;
  //   }

  //   const vncSessionId = generateHashForVncSession();
  //   const configForRealTimeTesting = {
  //     os,
  //     browser,
  //     version,
  //     urlForVncSession: url,
  //     userId,
  //     vncSessionId,
  //   };

  //   dispatch(uiActions.setConfigForRealTimeTesting(configForRealTimeTesting));
  //   dispatch(uiActions.toggleRealTimeTestingPreloader(true));
  // }

  const thumbnailImageStyle = { background: `url(${preview}) no-repeat center top / cover` };

  return (
    <div
      className={'thumbnail'}
      ref={screenRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="thumbnail__top">
        <div
          className="thumbnail__overlay"
          title={`${osTitle}, ${browser} ${version}, ${resolution}px`}
        >
          <button className="thumbnail__overlay-view" onClick={viewScreen}>
            <span className="thumbnail__overlay-view-label oval-btn">{viewscreen}</span>
          </button>
          {/* {isDesktop && ['LINUX', '10WINDOWS'].includes(os) && !['90.0', '91.0'].includes(version) && (
            <button
              className="thumbnail__overlay-run-live-test orange-btn oval-btn"
              onClick={handleRunLiveTestClick}
              title="Run live test"
            >
              Run live test
            </button>
          )} */}
          <button
            className="thumbnail__overlay-check"
            onClick={checkScreen}
            title="Select screenshot"
          />
          <button
            className="thumbnail__overlay-update"
            disabled={!preview}
            onClick={updateScreen}
            title={refreshScreenshot}
          >
            <Icons.updateMiniWhite />
          </button>
          <button
            className="thumbnail__overlay-delete"
            onClick={deleteScreen}
            title={deleteScreenshot}
          >
            <Icons.deleteMiniWhite />
          </button>
        </div>
        {status === 'available' && (
          <div className="thumbnail__image" style={thumbnailImageStyle}>
            <button className="thumbnail__view-mobile" onClick={viewScreen} />
          </div>
        )}
        {status === 'loading' && (
          <div className="thumbnail__loading">
            <img className="thumbnail__loading-img" src={loadingIcon} alt="" />
            <button className="thumbnail__view-mobile" onClick={viewScreen} />
          </div>
        )}
        {status === 'error' && (
          <div className="thumbnail__error">
            <button className="thumbnail__view-mobile" onClick={viewScreen} />
          </div>
        )}
        {!selectionStatus && (
          <div className="thumbnail__mobile">
            <button className="thumbnail__mobile-btn" onClick={() => openMenu(true)}>
              <span className="thumbnail__mobile-btn-label" />
            </button>
            <div className={menuClasses} ref={menuRef}>
              <button
                className="thumbnail__mobile-menu-update"
                disabled={!preview}
                onClick={updateScreen}
              >
                <Icons.updateMiniWhite /> {update}
              </button>
              <button className="thumbnail__mobile-menu-delete" onClick={deleteScreen}>
                <Icons.deleteMiniWhite /> {Delete}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="thumbnail__bottom">
        <div className="thumbnail__bottom-os">
          <img src={osIcon} className="thumbnail__bottom-img" alt="" title={osTitle} />
          <span>{isDesktop ? defineOsTitle(os) : defineOsTitleMobile(os)}</span>
        </div>
        <div className="thumbnail__bottom-browser">
          <img
            src={browserIcon}
            className="thumbnail__bottom-img"
            alt={browser}
            title={browserTitle}
          />
          <span>{version}</span>
        </div>
      </div>
      <label className={selectClasses}>
        <input
          type="checkbox"
          className="thumbnail__select-input"
          checked={checked}
          onChange={handleChange}
        />
        <div className="thumbnail__select-overlay">
          <button
            className="thumbnail__select-overlay-view-btn"
            onClick={viewScreen}
            onMouseEnter={() => setBlur(true)}
            onMouseLeave={() => setBlur(false)}
          />
          <span className={selectOverlayLabelClasses}>{checked ? 'Deselect' : 'Select'}</span>
        </div>
        <span className={checkboxClasses} />
        <button className="thumbnail__select-view-btn-mobile" onClick={viewScreen} />
      </label>
    </div>
  );
}

export default React.memo(Screenshot);
