// core
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import TableConfigsDesktop from '../tableConfigsDesktop';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
import { screensActions } from '../../store/domains/screenshots/actions';
import { configsCheckboxesWithURLActions } from '../../store/domains/configsCheckboxesWithURL/actions';
import { configsCheckboxesNoURLActions } from '../../store/domains/configsCheckboxesNoURL/actions';
// instruments
import { useAuth0 } from '../../helpers/auth';
import { handleSubmitConfigNoURL, checkIfCurrentConfigsCanBeChecked } from '../../helpers';
import useTranslations from '../../hooks/useTranslations';
//default settings
import { app_metadata_namespace } from '../../settings.json';
//socket
import { _socket as socket } from '../../socket';
// typescript types
import { RootState } from '../../store/rootReducer';

function ConfigDesktopNoURL(props) {
  // hooks
  const dispatch = useDispatch();
  const url = useSelector((state: RootState) => state.ui.url);
  const filter = useSelector((state: RootState) => state.resolutions.filter);
  const screens = useSelector((state: RootState) => state.screens);
  const selectionStatus = useSelector((state: RootState) => state.ui.massSelectionStatus);
  const checkboxes = useSelector((state: RootState) => state.configsCheckboxesNoURL);
  const submittedResolutions = useSelector(
    (state: RootState) => state.resolutions.submittedResolutions
  );
  const resolutions = useSelector((state: RootState) => state.resolutions.resolutions);
  const credentials = useSelector((state: RootState) => state.ui.credentialsForBasicAuth);
  const profiles = useSelector((state: RootState) => state.loginProfiles.profiles);
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isSmartScrollOn = useSelector((state: RootState) => state.ui.isSmartScrollOn);
  const [loginProfileId, setLoginProfileId] = useState(); // id of selected login profile
  const formRef = useRef(null);
  const { addScreens } = useTranslations();
  const { user } = useAuth0();

  // actions
  const {
    checkAllConfigs,
    checkBrowserGroupConfigs,
    checkOSGroupConfigs,
  } = configsCheckboxesNoURLActions;

  // memoized values
  const memoizedCheckBrowserGroupConfigs = useCallback(checkBrowserGroupConfigs, []);
  const memoizedCheckOSGroupConfigs = useCallback(checkOSGroupConfigs, []);
  const memoizedCheckAllConfigs = useCallback(checkAllConfigs, []);

  // define current resolution
  const currentResolution = filter
    ? filter
    : submittedResolutions.length === 1
    ? submittedResolutions[0]
    : submittedResolutions;
  const resolutionsToDisplay = Array.isArray(currentResolution)
    ? currentResolution.join(' px, ')
    : currentResolution;

  useEffect(() => {
    function hidePanel(event) {
      if (
        !formRef.current.contains(event.target) &&
        event.target.className.indexOf('demoreminder') === -1
      ) {
        dispatch(uiActions.hideConfigDesktopNoURL());
      }
    }

    document.addEventListener('click', hidePanel);

    return () => document.removeEventListener('click', hidePanel);
  }, []);

  // find out if one of the login profiles is selected and get its ID
  useEffect(() => {
    const selectedProfile = profiles.find((profile) => profile.selected);

    setLoginProfileId(selectedProfile?.id);
  }, [profiles]);

  function handleSubmit(event) {
    event.preventDefault();

    const userId = user?.sub;
    const { user_auth, user_pass } = credentials;
    const {
      areScreensUnlimited,
      license_subscription_screens_count: screensLimit,
      count_screens_for_period: screensCount,
    } = userLimitsAndStats;

    if (!areScreensUnlimited) {
      const numberOfCheckedResolutions = resolutions.filter(({ checked }) => checked).length;

      const params = {
        dispatch,
        uiActions,
        screensLimit,
        screensCount,
        checkboxes,
        numberOfCheckedResolutions,
        multiplyByNumberOfResolutions: false,
      };

      const canBeChecked = checkIfCurrentConfigsCanBeChecked(params);
      if (!canBeChecked) return;
    }

    const {
      license_product: licenseProduct,
      billing_operator: billingOperator,
      last_order_reference: lastOrderReference,
    } = user?.[app_metadata_namespace]?.comparium?.subscription || {};

    handleSubmitConfigNoURL(
      url,
      socket,
      checkboxes,
      dispatch,
      screensActions,
      configsCheckboxesWithURLActions,
      currentResolution,
      screens,
      userId,
      user_auth,
      user_pass,
      loginProfileId,
      props.screensSessionId,
      licenseProduct,
      billingOperator,
      lastOrderReference,
      isSmartScrollOn
    );

    if (selectionStatus) {
      dispatch(screensActions.uncheckAllScreens());
      dispatch(uiActions.toggleSelectionStatus(false));
    }

    dispatch(uiActions.hideConfigDesktopNoURL());
  }

  return (
    <form className="configurations no-url" ref={formRef} onSubmit={handleSubmit}>
      <p className="configurations__width">
        Width: <span className="configurations__width-black">{`${resolutionsToDisplay} px`}</span>
      </p>
      <TableConfigsDesktop
        checkboxes={checkboxes}
        mayBeAlreadyChecked={false}
        checkBrowserGroupConfigs={memoizedCheckBrowserGroupConfigs}
        checkOSGroupConfigs={memoizedCheckOSGroupConfigs}
        checkAllConfigs={memoizedCheckAllConfigs}
        buttonLabel={addScreens}
      />
    </form>
  );
}

export default React.memo(ConfigDesktopNoURL);
