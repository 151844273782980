import {
  AddResolutionAction,
  DeleteResolutionAction,
  CheckOrUncheckAllResolutionsAction,
  AddSubmittedResolutionsAction,
  SetFilterAction,
  FillFilteredScreensAction,
  CheckResolutionAction,
  UncheckResolutionAction,
  CheckResolutionsAction,
} from './types';
import { ScreenConfig } from '../commonTypes';

export const resolutionsActions = {
  addResolution(resolution: number): AddResolutionAction {
    return { type: 'ADD_RESOLUTION', payload: resolution };
  },
  deleteResolution(resolution: number): DeleteResolutionAction {
    return { type: 'DELETE_RESOLUTION', payload: resolution };
  },
  checkResolution(resolution: number): CheckResolutionAction {
    return { type: 'CHECK_RESOLUTION', payload: resolution };
  },
  uncheckResolution(resolution: number): UncheckResolutionAction {
    return { type: 'UNCHECK_RESOLUTION', payload: resolution };
  },
  checkResolutions(resolutions: number[]): CheckResolutionsAction {
    return { type: 'CHECK_RESOLUTIONS', payload: resolutions };
  },
  addSubmittedResolutions(resolutions: number[]): AddSubmittedResolutionsAction {
    return { type: 'ADD_SUBMITTED_RESOLUTIONS', payload: resolutions };
  },
  setFilter(resolution: 'all' | number): SetFilterAction {
    return { type: 'SET_FILTER', payload: resolution };
  },
  fillFilteredScreens(data: ScreenConfig[]): FillFilteredScreensAction {
    return { type: 'FILL_FILTERED_SCREENS', payload: data };
  },
  checkOrUncheckAllResolutions(): CheckOrUncheckAllResolutionsAction {
    return { type: 'CHECK_OR_UNCHECK_ALL_RESOLUTIONS' };
  },
};
