export const selectStyles = {
  menu: styles => ({
    ...styles,
    margin: '1px 0 0 0',
    boxShadow: 'none',
    zIndex: 4,
  }),
  menuList: styles => ({
    ...styles,
    background: '#fff',
    borderRadius: 5,
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: 139,
    minWidth: 96,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
  }),
  option: (styles, state) => ({
    padding: '8px 12px',
    fontSize: 14,
    position: 'relative',
    cursor: 'pointer',
    textTransform: 'uppercase',
    color: state.isSelected ? '#fff' : '#9DA2AA',
    backgroundColor: state.isSelected ? '#3DC3E2 !important' : '',
    '&:hover': {
      backgroundColor: '#F1F3F8',
    },
  }),
  container: styles => ({
    ...styles,
    width: 96,
    height: 34,
    cursor: 'pointer',
    marginLeft: 5,
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.menuIsOpen ? '#FDD79F' : 'rgba(201, 205, 211, 0.8)',
    boxShadow: state.menuIsOpen ? '0 0 0 1px #FDD79F' : 'none',
    backgroundColor: 'transparent',
    borderRadius: 5,
    minHeight: 'auto',
    height: '100%',
    color: '#606772',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  singleValue: styles => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    fontSize: 14,
    maxWidth: 'auto',
    marginLeft: 0,
    marginRight: 0,
    textTransform: 'uppercase',
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 0 0 10px',
    overflow: 'visible',
  }),
};
